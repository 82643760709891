import React, { Component } from "reactn";
import styled from "styled-components";
import moment from "moment";
import { DialogActions, DialogTitle, CircularProgress } from "@material-ui/core";

import { _ } from "legacy-js/vendor";
import { app } from "js/namespaces";
import Api from "js/core/api";
import getLogger, { LogGroup } from "js/core/logger";
import { trackActivity } from "js/core/utilities/utilities";
import { emailRegex } from "js/core/utilities/regex";
import { CustomerType, TEAM_USER_LICENSE_STATUS, TEAM_USER_ROLES, AnalyticsRolesAndLicensesMap } from "legacy-common/constants";
import { RowGrid } from "legacy-js/react/components/LayoutGrid";
import { Gap20 } from "legacy-js/react/components/Gap";
import { BeautifulDialog, ShowDialog, ShowErrorDialog, DialogContent } from "legacy-js/react/components/Dialogs/BaseDialog";
import AddTeamMembers from "legacy-js/react/components/AddTeamMembers";
import TextArea from "legacy-js/react/components/TextArea";
import Loadable from "legacy-js/react/components/Loadable";
import { BlueButton, BlueOutlinedButton } from "legacy-js/react/components/UiComponents";
import withStripe from "legacy-js/react/views/UserOptions/Billing/withStripe";
import ChangePaymentMethodDialog from "legacy-js/react/views/UserOptions/Billing/ChangePaymentMethodDialog";

const logger = getLogger(LogGroup.TEAMS);

const StyledLink = styled.a`
  color: #11A9E2;
  margin-left: 2px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #0D76A2;
  }
`;

const SeatCountContainer = styled.div`
    font-size: 14px;
    color: #2a9747;
`;

const NoSeatCountContainer = styled.div`
    font-size: 14px;
    color: red;
`;

const StyledCircularProgress = styled(CircularProgress)`
    color: #23aae0;
`;

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95px;
`;

class InviteTeamMembersDialog extends Component {
    inflightRequests = 0;
    inflightInvoiceQuantity = 0;

    state = {
        org: {},
        members: [
            {
                id: _.uniqueId(),
                email: "",
                role: TEAM_USER_ROLES.MEMBER_LICENSED
            }
        ],
        customMessage: "",
        isInitializing: true,
        isLoading: true,
        existTeamMembersObj: {},
        paymentMethod: {},
        subscription: { items: { data: [{}] } },
        invoiceTotalToday: 0,
        invoiceTotalFuture: 0,
        plan: "team"
    };

    get assignedSeatCount() {
        let count = 0;
        this.props.team.members.forEach(member => {
            if (member.hasSeat) {
                count++;
            } else if (member.pending && member.license === TEAM_USER_LICENSE_STATUS.TEAM_PRO) {
                count++;
            }
        });
        return count;
    }

    get orgTotalSeatCount() {
        const { subscription } = this.state;
        return subscription.items.data[0].quantity;
    }

    async componentDidMount() {
        const org = await this.fetchOrg();

        const { team } = this.props;
        const existTeamMembersObj = team.members.reduce((acc, curr) => {
            acc[curr["email"]] = curr;
            return acc;
        }, {});

        const paymentMethodsResponse = await Api.paymentMethods.get({
            customer_type: CustomerType.TEAM,
            organization_id: org.id
        });
        const subscriptions = await Api.subscriptions.get({
            customer_type: CustomerType.TEAM,
            organization_id: org.id
        });
        //TODO remove the logic for team-migrated plan when old monthly/annual team customers migrate to the new Team plan
        const plan =
            subscriptions[0].items.data[0].plan.id === "plan_HEQmYB4HfYaXxc" || subscriptions[0].items.data[0].plan.id === "plan_HEQnBa6SxskXOc" ? "team-migrated" : "team";
        this.setState(
            {
                paymentMethod: paymentMethodsResponse.body[0],
                subscription: subscriptions[0],
                existTeamMembersObj,
                plan
            },
            () => {
                this.getUpcomingStripeInvoice(1);
            }
        );
    }

    getUpcomingStripeInvoice = async newSeats => {
        const { org, subscription } = this.state;
        const quantity = this.orgTotalSeatCount + newSeats;
        const currentSeatCount = this.orgTotalSeatCount;
        const prorationDate = Math.floor(new Date() / 1000);

        if (this.state.invoiceQuantity === newSeats || this.inflightInvoiceQuantity === newSeats) {
            // cached - no change, nothing to fetch
            return;
        }

        if (this.inflightRequests === 0) {
            this.setState({ isLoading: true });
        }
        this.inflightRequests++;
        this.inflightInvoiceQuantity = newSeats;

        try {
            const { body: invoiceToday } = await Api.subscriptions.put({
                customerType: CustomerType.TEAM,
                orgId: org.id,
                quantity: currentSeatCount + newSeats,
                prorationDate,
                type: "estimate_increase_quantity"
            });

            let invoiceFuture;
            // Stripe will error out if the subscription has been canceled, so we skip
            if (!subscription.cancel_at_period_end) {
                invoiceFuture = (await Api.invoices.post({
                    type: "preview_upcoming",
                    orgId: org.id,
                    prorate: false,
                    updates: {
                        subscription_items: [{ quantity }]
                    }
                }))?.body;
            }

            this.setState({
                invoiceTotalToday: invoiceToday.total,
                invoiceTotalFuture: invoiceFuture?.total || 0,
                invoiceQuantity: newSeats,
                prorationDate
            });
        } catch (err) {
            logger.error(err, "getUpcomingStripeInvoice() failed", {
                orgId: org.id,
                quantity: currentSeatCount + newSeats
            });
        } finally {
            this.inflightRequests--;
            if (this.inflightRequests === 0) {
                this.inflightInvoiceQuantity = 0;
                this.setState({
                    isLoading: false,
                    isInitializing: false
                });
            }
        }
    };

    static get type() {
        return "InviteTeamMembers";
    }

    fetchOrg = async () => {
        const orgResponse = await Api.organizations.get({ id: this.props.organizationId });
        this.setState({ org: orgResponse });
        return orgResponse;
    };

    handleSendEmailClicked = async () => {
        const { team, usedSeatCount, stripe } = this.props;
        const { isLoading, org, members, customMessage, subscription, prorationDate } = this.state;
        const currentSeatCount = this.orgTotalSeatCount;
        const newSeats = members.filter(member => member.role !== TEAM_USER_ROLES.MEMBER).length - (currentSeatCount - usedSeatCount);

        if (isLoading) return;
        this.setState({ isLoading: true });
        try {
            if (!this.hasEnoughSeats()) {
                const totalSeatCount = currentSeatCount + newSeats;

                const { body: { requiresAction, clientSecret, invoiceId, paymentMethod } } = await Api.subscriptions.put({
                    customerType: CustomerType.TEAM,
                    orgId: org.id,
                    quantity: totalSeatCount,
                    prorationDate,
                    type: "increase_quantity"
                });

                if (requiresAction) {
                    const { error } = await stripe.handleCardPayment(clientSecret, { payment_method: paymentMethod });

                    // This will either extend the subscription or void the invoice if the payment failed
                    await Api.subscriptions.put({
                        customerType: CustomerType.TEAM,
                        orgId: org.id,
                        quantity: totalSeatCount,
                        prorationDate,
                        type: "increase_quantity_finalize",
                        invoiceId
                    });

                    if (error) {
                        throw error;
                    }
                }

                const billingProps = {
                    workspace_id: org.id,
                    seats_added: newSeats,
                    type: "expansion",
                    seat_recipients: members.map(m => m.email),
                    billing_term: subscription.interval,
                    total_seats: totalSeatCount
                };
                trackActivity("Organization", "BillingIntentComplete", null, null, billingProps, { audit: true });
                await Api.klaviyoTrack.post({
                    eventName: "Organization:BillingIntentComplete",
                    billingProps
                });
            }

            await Api.teams.put({
                teamId: team.id,
                type: "add_users",
                members,
                customMessage,
                orgId: team.orgId
            });

            const props = {
                workspace_id: team.orgId,
                recipients: members.map(m => m.email),
                user_roles: members.map(m => AnalyticsRolesAndLicensesMap[m.role]),
                user_license: members.map(m => {
                    if (m.role === TEAM_USER_ROLES.MEMBER) {
                        return TEAM_USER_LICENSE_STATUS.FREE;
                    }
                    return TEAM_USER_LICENSE_STATUS.TEAM_PRO;
                })
            };
            trackActivity("Organization", "InviteSent", null, null, props, { audit: true });

            this.props.closeDialog();
            this.props.callback();
        } catch (err) {
            if (err) {
                logger.error(err, "handleSendEmailClicked() failed", {
                    orgId: org.id,
                    quantity: currentSeatCount + newSeats
                });

                ShowErrorDialog({
                    error: "Unable to invite team members",
                    message: err.message
                });
            }
            this.setState({ isLoading: false });
        }
    };

    handleUpdatePaymentMethod = async () => {
        const { org } = this.state;
        ShowDialog(ChangePaymentMethodDialog, {
            orgId: org.id,
            callback: async () => {
                const paymentMethodsResponse = await Api.paymentMethods.get({
                    customer_type: CustomerType.TEAM,
                    team_id: org.id
                });
                this.setState({
                    paymentMethod: paymentMethodsResponse.body[0]
                });
            }
        });
    };

    handleMemberUpdate = (id, propKey, propValue) => {
        this.setState(
            prevState => ({
                ...prevState,
                members: prevState.members.map(member => ({
                    ...member,
                    [propKey]: member.id === id ? propValue : member[propKey]
                }))
            }),
            () => {
                const numNewSeats = this.state.members.filter(member => member.role !== TEAM_USER_ROLES.MEMBER).length;
                this.getUpcomingStripeInvoice(numNewSeats);
            }
        );
    };

    handleAddMember = () => {
        this.setState(
            prevState => ({
                ...prevState,
                members: prevState.members.concat({
                    id: _.uniqueId(),
                    email: "",
                    role: TEAM_USER_ROLES.MEMBER_LICENSED
                })
            }),
            () => {
                const numNewSeats = this.state.members.filter(member => member.role !== TEAM_USER_ROLES.MEMBER).length;
                this.getUpcomingStripeInvoice(numNewSeats);
            }
        );
    };

    handleRemoveMember = id => {
        this.setState(
            prevState => ({
                ...prevState,
                members: prevState.members.filter(member => member.id !== id)
            }),
            () => {
                const numNewSeats = this.state.members.filter(member => member.role !== TEAM_USER_ROLES.MEMBER).length;
                this.getUpcomingStripeInvoice(numNewSeats);
            }
        );
    };

    hasEnoughSeats = () => {
        const { usedSeatCount } = this.props;
        const { members } = this.state;
        const proMembers = members.filter(member => member.role !== TEAM_USER_ROLES.MEMBER);
        return usedSeatCount + proMembers.length <= this.orgTotalSeatCount;
    };

    numTierSeatsAvailable = () => {
        const { members } = this.state;
        const numExistingSeats = this.orgTotalSeatCount;
        const numNewSeats = members.filter(member => member.role !== TEAM_USER_ROLES.MEMBER).length;

        const { subscription } = this.state;
        const price = subscription.items.data[0].price;

        if (price?.billing_scheme === "tiered") {
            let numSeatsAvailable = 0;
            for (const tier of subscription.tiers) {
                if (numExistingSeats <= tier.up_to) {
                    numSeatsAvailable = tier.up_to;
                    break;
                }
            }
            numSeatsAvailable = Math.max(0, numSeatsAvailable - numExistingSeats - numNewSeats);
            return numSeatsAvailable;
        } else {
            throw new Error("not using tiered pricing");
        }
    };

    checkIfFormIsValid = () => {
        const { isEnterprise } = this.props;
        const { members, existTeamMembersObj } = this.state;

        if (isEnterprise && !this.hasEnoughSeats()) {
            return false;
        }

        const memberCounts = members.reduce((acc, curr) => {
            if (curr.email.length) {
                if (!acc[curr.email.toLowerCase()]) {
                    acc[curr.email.toLowerCase()] = 1;
                } else {
                    ++acc[curr.email.toLowerCase()];
                }
            }
            return acc;
        }, {});

        //if the member email format is invalid OR the email matches the auth user then its invalid
        // OR there are duplicate emails in the form OR the email matches an existing team member then the email is considered invalid
        const invalidEmailFound = members.some(
            member =>
                !member.email.match(emailRegex) ||
                member.email === app.user.getEmail() ||
                Object.values(memberCounts).some(count => count > 1) ||
                !!existTeamMembersObj[member.email.toLowerCase()]
        );

        return !invalidEmailFound;
    };

    render() {
        const { team, isEnterprise } = this.props;
        const { members, customMessage, isLoading, org, existTeamMembersObj, paymentMethod, subscription, invoiceTotalToday, invoiceTotalFuture, isInitializing } = this.state;
        const currentSeatCount = this.orgTotalSeatCount;
        const newSeats = members.filter(member => member.role !== TEAM_USER_ROLES.MEMBER).length;
        const price = subscription.items.data[0].price;
        const hasTieredPricing = price?.billing_scheme === "tiered";
        const numTierSeatsAvailable = hasTieredPricing ? this.numTierSeatsAvailable() : 0;

        const canAddNewSeats = !!paymentMethod;

        const cardType = paymentMethod?.brand;
        const cardNumber = `****${paymentMethod?.last4}`;
        const nextBillingDate = moment(subscription.current_period_end * 1000);

        const numAvailableSeats = Math.max(0, this.orgTotalSeatCount - this.assignedSeatCount - newSeats);
        const numActualNewSeats = newSeats - (this.orgTotalSeatCount - this.assignedSeatCount);

        return (
            <BeautifulDialog maxWidth="md" fullWidth={false} preventClose>
                <DialogTitle>Invite people to join {team.name}</DialogTitle>
                <DialogContent>
                    <Loadable isLoading={isInitializing}>
                        <Gap20 />
                        {/* prevent editing of the form while request is inflight */}
                        <RowGrid style={{ pointerEvents: isLoading ? "none" : "all" }}>
                            <AddTeamMembers
                                existingTeamMembers={existTeamMembersObj}
                                members={members}
                                onUpdateMember={this.handleMemberUpdate}
                                onAddMember={this.handleAddMember}
                                onRemoveMember={this.handleRemoveMember}
                                showLicenseDropdown={true}
                                billingTerm={subscription.interval}
                                maxSeatCount={canAddNewSeats ? null : this.orgTotalSeatCount - this.assignedSeatCount}
                            />
                            {!hasTieredPricing && numAvailableSeats > 0 && (
                                <SeatCountContainer>
                                    {numAvailableSeats} more Team Pro seat{numAvailableSeats > 1 ? "s" : ""} available.
                                </SeatCountContainer>
                            )}
                            {!this.hasEnoughSeats() && isEnterprise && (
                                <NoSeatCountContainer>
                                    You have no available Team Pro seats.
                                    <StyledLink href="mailto:accounts@beautiful.ai">Contact us to add more seats.</StyledLink>
                                </NoSeatCountContainer>
                            )}
                            {hasTieredPricing && numTierSeatsAvailable > 0 && (
                                <SeatCountContainer>
                                    {numTierSeatsAvailable} more Team Pro seat{numTierSeatsAvailable > 1 ? "s" : ""} remaining in your tier.
                                </SeatCountContainer>
                            )}
                            <TextArea
                                isVisible
                                text={customMessage}
                                onChange={customMessage => {
                                    this.setState({
                                        customMessage
                                    });
                                }}
                                placeholder="Add a note..."
                            />

                            {!this.hasEnoughSeats() && !isEnterprise && (
                                <div className="summary" style={{ minWidth: 503, minHeight: 125 }}>
                                    {isLoading && (
                                        <SpinnerContainer>
                                            <StyledCircularProgress size={20} />
                                        </SpinnerContainer>
                                    )}
                                    {!isLoading && (
                                        <>
                                            You are adding{" "}
                                            <strong>
                                                {numActualNewSeats} new Team Pro Seat{numActualNewSeats > 1 ? "s" : ""}
                                            </strong>
                                            .
                                            {invoiceTotalToday === 0 && (
                                                <>
                                                    <br />
                                                    <br />
                                                    You will not be charged today.
                                                </>
                                            )}
                                            {invoiceTotalToday > 0 && (
                                                <>
                                                    <br />
                                                    <br />
                                                    You will be charged <strong>${(invoiceTotalToday / 100).toFixed(2)}</strong> today on your{" "}
                                                    <strong>
                                                        {cardType} {cardNumber}
                                                    </strong>{" "}
                                                    (
                                                    <a
                                                        style={{
                                                            color: "#11A9E2",
                                                            cursor: "pointer",
                                                            pointerEvents: "auto"
                                                        }}
                                                        onClick={this.handleUpdatePaymentMethod}
                                                    >
                                                        change
                                                    </a>
                                                    ).
                                                </>
                                            )}
                                            <br />
                                            <br />
                                            Your next {subscription.interval}ly bill of <strong>${(invoiceTotalFuture / 100).toFixed(2)}</strong> for{" "}
                                            {isNaN(newSeats) ? currentSeatCount : currentSeatCount + newSeats} total seats will be due on{" "}
                                            <strong>{nextBillingDate.format("MMM DD YYYY")}</strong>.
                                        </>
                                    )}
                                </div>
                            )}
                            <DialogActions>
                                <BlueOutlinedButton onClick={this.props.closeDialog}>Cancel</BlueOutlinedButton>
                                <BlueButton disabled={!this.checkIfFormIsValid()} onClick={this.handleSendEmailClicked} color="primary">
                                    {(isEnterprise || this.hasEnoughSeats()) ? "Invite Organization Members" : "Add Seats + Send Invite"}
                                </BlueButton>
                            </DialogActions>
                        </RowGrid>
                    </Loadable>
                </DialogContent>
            </BeautifulDialog>
        );
    }
}

export default withStripe(InviteTeamMembersDialog);

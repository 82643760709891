import React, { Component } from "react";
import classNames from "classnames";
import {
    DialogTitle,
    DialogActions,
    TextField
} from "@material-ui/core";

import { BeautifulDialog, DialogContent } from "legacy-js/react/components/Dialogs/BaseDialog";
import Spinner from "legacy-js/react/components/Spinner";
import { BlueButton, BlueOutlinedButton } from "legacy-js/react/components/UiComponents";
import { emailRegex } from "js/core/utilities/regex";

export default class UpdateEmailRecipientDialog extends Component {
    state = {
        isSubmitting: false,
        email: ""
    };

    get isEmailValid() {
        const { email } = this.state;
        if (!email.length) {
            return true;
        }
        const { currentCustomerEmail } = this.props;
        return email.match(emailRegex) && email !== currentCustomerEmail;
    }

    render() {
        const { isSubmitting, email } = this.state;

        return (
            <BeautifulDialog
                hideBackdrop={this.props.hideBackdrop}
                closeDialog={this.props.closeDialog}
            >
                <DialogTitle>
                    Where would you like to send receipts?
                </DialogTitle>
                <DialogContent className={
                    classNames(
                        { disabled: isSubmitting }
                    )}>
                    {isSubmitting && <Spinner />}
                    <TextField
                        style={{ width: "100%" }}
                        variant="outlined"
                        InputProps={{ placeholder: "Type email..." }}
                        value={email}
                        onChange={event => this.setState({ email: event.target.value })}
                        error={!this.isEmailValid}
                        helperText={!this.isEmailValid && `Please enter a valid email.`}
                    />
                </DialogContent>
                <DialogActions>
                    <BlueOutlinedButton
                        onClick={this.props.closeDialog}
                    >
                        Cancel
                    </BlueOutlinedButton>
                    <BlueButton
                        disabled={!email.length || !this.isEmailValid}
                        onClick={() => {
                            this.props.onAccept(email);
                            this.props.closeDialog();
                        }}>
                        Update
                    </BlueButton>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}

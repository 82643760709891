import React, { Component } from "react";
import styled from "styled-components";
import Api from "js/core/api";
import { Button, DialogActions, DialogTitle, TextField, CircularProgress } from "@material-ui/core";
import moment from "moment";
import { Gap20, Gap30 } from "legacy-js/react/components/Gap";
import { BeautifulDialog, DialogContent, ShowDialog, ShowErrorDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { CustomerType } from "legacy-common/constants";
import getLogger, { LogGroup } from "js/core/logger";
import ChangePaymentMethodDialog from "../Billing/ChangePaymentMethodDialog";
import { trackActivity } from "js/core/utilities/utilities";
import withStripe from "legacy-js/react/views/UserOptions/Billing/withStripe";

const logger = getLogger(LogGroup.TEAMS);

const StyledTextField = styled(TextField)`
    vertical-align: middle;
    font-size: 17;
    font-weight: 600;
`;

const StyledCircularProgressContainer = styled.div`
    display: flex; 
    justify-content: center; 
    align-items: center;
`;

const StyledCircularProgress = styled(CircularProgress)`
    align-self: center;
`;

class AddTeamSeatDialog extends Component {
    async componentDidMount() {
        const { org, teamName, initialNewSeatCount } = this.props;
        const paymentMethodsResponse = await Api.paymentMethods.get({
            customer_type: CustomerType.TEAM,
            organization_id: org.id
        });
        const subscriptions = await Api.subscriptions.get({
            customer_type: CustomerType.TEAM,
            organization_id: org.id
        });
        //TODO remove the logic for team-migrated plan when old monthly/annual team customers migrate to the new Team plan
        const plan = (subscriptions[0].items.data[0].plan.id === "plan_HEQmYB4HfYaXxc" ||
            subscriptions[0].items.data[0].plan.id === "plan_HEQnBa6SxskXOc")
            ? "team-migrated"
            : "team";
        this.setState({
            paymentMethod: paymentMethodsResponse.body[0],
            subscription: subscriptions[0],
            newSeats: initialNewSeatCount || 1,
            plan
        });
        this.getUpcomingStripeInvoice(1);
    }

    state = {
        newSeats: 1,
        paymentMethod: {},
        subscription: {},
        isLoading: true,
        addSeatDisabled: false,
        invoiceTotal: 0,
        plan: "team"
    };

    handleAccept = async () => {
        //userId will be passed as prop if the AddTeamSeatDialog renders while attempting to upgrade a user when a team
        //does not have enough seats available
        const { org, currentSeatCount, userId, stripe } = this.props;
        const { isLoading, newSeats, prorationDate } = this.state;
        if (isLoading) return;
        this.setState({ isLoading: true });
        try {
            const totalSeatCount = currentSeatCount + newSeats;

            const { body: { requiresAction, clientSecret, invoiceId, paymentMethod } } = await Api.subscriptions.put({
                customerType: CustomerType.TEAM,
                orgId: org.id,
                quantity: totalSeatCount,
                prorationDate,
                type: "increase_quantity"
            });

            if (requiresAction) {
                const { error } = await stripe.handleCardPayment(clientSecret, { payment_method: paymentMethod });

                // This will either extend the subscription or void the invoice if the payment failed
                await Api.subscriptions.put({
                    customerType: CustomerType.TEAM,
                    orgId: org.id,
                    quantity: totalSeatCount,
                    prorationDate,
                    type: "increase_quantity_finalize",
                    invoiceId
                });

                if (error) {
                    throw error;
                }
            }

            await Api.organizations.put({
                id: org.id,
                type: "add_seats",
                seatCount: newSeats,
                userIds: userId ? [userId] : null
            });

            const billingProps = {
                workspace_id: org.id,
                seats_added: newSeats,
                type: "expansion",
                total_seats: currentSeatCount + newSeats
            };
            trackActivity("Organization", "BillingIntentComplete", null, null, billingProps, { audit: true });
            await Api.klaviyoTrack.post({
                eventName: "Organization:BillingIntentComplete",
                billingProps,
            });
        } catch (err) {
            if (err) {
                logger.error(err, "AddTeamSeatDialog handleAccept() failed", { orgId: org.id });

                ShowErrorDialog({
                    error: "Failed to add seats",
                    message: "Something went wrong. Please try again and reach out to support@beautiful.ai if the issue persists."
                });
            }
        }
        this.handleCloseDialog();
    };

    handleUpdatePaymentMethod = async () => {
        const { org } = this.props;
        ShowDialog(ChangePaymentMethodDialog, {
            orgId: org.id,
            callback: async () => {
                const paymentMethodsResponse = await Api.paymentMethods.get({
                    customer_type: CustomerType.TEAM,
                    team_id: org.id
                });
                this.setState({
                    paymentMethod: paymentMethodsResponse.body[0]
                });
            }
        });
    };

    renderNewSeatCount(newSeatCount) {
        if (isNaN(newSeatCount)) {
            return 0;
        }
        return newSeatCount;
    }

    handleCloseDialog = () => {
        const { closeDialog, callback } = this.props;
        callback();
        closeDialog();
    }

    handleNewSeatCountChange = async event => {
        const newSeats = parseInt(event.target.value);
        this.setState({ newSeats, addSeatDisabled: newSeats === 0 });
        this.getUpcomingStripeInvoice(newSeats);
    }

    getUpcomingStripeInvoice = async newSeats => {
        const { org, currentSeatCount } = this.props;
        const { subscription } = this.state;
        this.setState({ isLoading: true });
        const quantity = currentSeatCount + newSeats;
        const prorationDate = Math.floor(new Date() / 1000);

        try {
            const { body: invoiceToday } = await Api.subscriptions.put({
                customerType: CustomerType.TEAM,
                orgId: org.id,
                quantity: currentSeatCount + newSeats,
                prorationDate,
                type: "estimate_increase_quantity"
            });

            let invoiceFuture;
            // Stripe will error out if the subscription has been canceled, so we skip
            if (!subscription.cancel_at_period_end) {
                invoiceFuture = (await Api.invoices.post({
                    type: "preview_upcoming",
                    orgId: org.id,
                    prorate: false,
                    updates: {
                        subscription_items: [{ quantity }],
                    },
                }))?.body;
            }

            this.setState({
                invoiceTotalToday: invoiceToday.total,
                invoiceTotalFuture: invoiceFuture?.total || 0,
                prorationDate,
                isLoading: false,
            });
        } catch (err) {
            logger.error(err, "AddTeamSeatDialog getUpcomingStripeInvoice() failed", { orgId: org.id });

            this.setState({ isLoading: false });
        }
    };

    render() {
        const { newSeats, paymentMethod, subscription, isLoading, addSeatDisabled, invoiceTotalToday, invoiceTotalFuture } = this.state;
        const { usedSeatCount, currentSeatCount } = this.props;

        const cardType = paymentMethod.brand;
        const cardNumber = `****${paymentMethod.last4}`;
        const nextBillingDate = moment(subscription.current_period_end * 1000);

        return (
            <BeautifulDialog closeDialog={this.handleCloseDialog}>
                <DialogTitle>Add Team Pro Seats</DialogTitle>
                <DialogContent>
                    <h3>
                        You currently using {usedSeatCount} of {currentSeatCount} Pro Seats purchased for this team.
                    </h3>
                    <Gap20 />
                    <div className="label" style={{ verticalAlign: "middle", fontSize: 17 }}>
                        How many seats would you like to add?
                    </div>
                    <StyledTextField
                        id="seat-count"
                        type="number"
                        value={newSeats}
                        variant="outlined"
                        inputProps={{
                            min: 1,
                            max: 99999,
                            step: 1
                        }}
                        onChange={this.handleNewSeatCountChange}
                    />
                    <Gap30 />
                    <div className="summary">
                        {isLoading && <StyledCircularProgressContainer>
                            <StyledCircularProgress size={20} />
                        </StyledCircularProgressContainer>}
                        {!isLoading && <>
                            You are adding{" "}
                            <strong>
                                {this.renderNewSeatCount(newSeats)} new Team Pro {"Seat".pluralize(newSeats > 1)}
                            </strong>
                            .
                            {invoiceTotalToday === 0 && (
                                <>
                                    <br />
                                    <br />
                                    You will not be charged today.
                                </>
                            )}
                            {invoiceTotalToday > 0 && (
                                <>
                                    <br />
                                    <br />
                                    You will be charged <strong>${(invoiceTotalToday / 100).toFixed(2)}</strong> today on your{" "}
                                    <strong>
                                        {cardType} {cardNumber}
                                    </strong>{" "}
                                    (
                                    <a
                                        style={{
                                            color: "#11A9E2",
                                            cursor: "pointer",
                                            pointerEvents: "auto"
                                        }}
                                        onClick={this.handleUpdatePaymentMethod}
                                    >
                                        change
                                    </a>
                                    ).
                                </>
                            )}
                            <br />
                            <br />
                            Your next bill of <strong>${(invoiceTotalFuture / 100).toFixed(2)}</strong> for{" "}
                            {isNaN(newSeats) ? currentSeatCount : currentSeatCount + newSeats} total seats will be due on{" "}
                            <strong>{nextBillingDate.format("MMM Do YYYY")}</strong>.
                        </>}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseDialog} color="secondary">
                        Cancel
                    </Button>
                    <Button disabled={addSeatDisabled} onClick={this.handleAccept} color="primary">
                        Add Seats
                    </Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}

export default withStripe(AddTeamSeatDialog);

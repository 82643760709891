import React, { Component } from "reactn";
import styled from "styled-components";
import moment from "moment";
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon
} from "@material-ui/icons";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Box,
    Collapse,
    IconButton,
    Typography,
} from "@material-ui/core";

import Loadable from "legacy-js/react/components/Loadable";
import { Section, BlueButton } from "legacy-js/react/components/UiComponents";
import { UserProfile } from "legacy-js/react/components/Avatar";
import Api from "js/core/api";
import getUserProfile from "js/core/services/userProfiles";
import { download } from "js/core/utilities/utilities";
import { _ } from "legacy-js/vendor";
import { formatter } from "js/core/utilities/formatter";
import Icon from "legacy-js/react/components/Icon";
import { ShowErrorDialog } from "legacy-js/react/components/Dialogs/BaseDialog";

const LogControls = styled.div`
    & > button, & > div {
        margin: 0 10px 10px 0;
    }
`;

const stringSplitRegex = /[A-Z][a-z]+-?[a-z]+/g; // find all words using a capital letter as word boundary, allow hyphens
const formatKey = key => key.split("_").map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(" ");
const formatDate = timestamp => moment.utc(timestamp).local().format("MMMM Do YYYY h:mm a");
const format = props => {
    return Object.entries(props || {}).map(pair => {
        let values = _.isObject(pair[1]) ? `${JSON.stringify(pair[1])}` : pair[1];
        if (values == null) return;
        if (pair[0] === "time") {
            values = formatDate(values);
        }
        return <>{`${formatKey(pair[0])}: `}<b>{`${values}`}</b><br /></>;
    });
};
function getRowProps(row) {
    const rowProps = { ...row, ...row.props };
    ["category", "action", "photoURL", "props", "invoker", "ipaddress", "windowHeight", "windowWidth", "value", "label", "emailVerified", "disabled", "context", "id", "current_plan", "workspace_type", "role", "current_slide_count"].forEach(key => delete rowProps[key]);
    return rowProps;
}

export default class LogsViewer extends Component {
    initialState = {
        isLogsFetching: true,
        startAt: new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString().split(".")[0],
        endAt: new Date().toISOString().split(".")[0]
    };

    state = { ...this.initialState };

    async componentDidMount() {
        await this.loadData();
    }

    async loadData() {
        const { organizationId } = this.props;
        const startAt = new Date(this.state.startAt).valueOf();
        const endAt = new Date(this.state.endAt).valueOf();
        if (!moment(startAt).isValid() || !moment(endAt).isValid()) {
            this.setState({ error: "Invalid date format" });
            return; // Exit if either date is invalid
        }
        try {
            const data = await Api.logs.get({ organizationId, format: "json", startAt, endAt });
            const logs = await Promise.all(data.map(async row => {
                const profile = await getUserProfile(row.invoker);
                return { ...profile, ...row };
            }));
            this.setState({ logs, isLogsFetching: false, error: null });
        } catch (err) {
            this.setState({ isLogsFetching: false, error: err.message });
            ShowErrorDialog({ title: "An error occurred while loading log data", message: err.message });
        }
    }

    async downloadLogs(format) {
        const { organizationId } = this.props;
        const startAt = new Date(this.state.startAt).valueOf();
        const endAt = new Date(this.state.endAt).valueOf();
        const res = await Api.logs.get({ organizationId, format, startAt, endAt }, false, true);
        const text = await res.text();
        download(`data:text/plain;charset=utf-8,${encodeURIComponent(text)}`, `logs:${startAt}-${endAt}.${format}`);
    }

    render() {
        const { isLogsFetching, logs, startAt, endAt } = this.state;

        return (
            <Loadable isLoading={isLogsFetching}>
                <Section title="Logs">
                    <LogControls className="log-controls">
                        <BlueButton onClick={() => this.loadData()}><Icon iconName="refresh" /></BlueButton>
                        <BlueButton onClick={() => this.downloadLogs("elff")}>Download Logs as ELFF</BlueButton>
                        <BlueButton onClick={() => this.downloadLogs("csv")}>Download Logs as CSV</BlueButton>
                        <BlueButton onClick={() => this.downloadLogs("json")}>Download Logs as JSON</BlueButton>
                        <TextField
                            id="startAt"
                            label="Start Time"
                            type="datetime-local"
                            value={startAt}
                            onChange={event => {
                                this.setState({ startAt: event.target.value }, this.loadData);
                            }}
                        />
                        <TextField
                            id="endAt"
                            label="End Time"
                            type="datetime-local"
                            value={endAt}
                            onChange={event => {
                                this.setState({ endAt: event.target.value }, this.loadData);
                            }}
                        />
                    </LogControls>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>User</TableCell>
                                    <TableCell>Action</TableCell>
                                    <TableCell>IP Address</TableCell>
                                    <TableCell>Time</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {logs && logs.map(row => <Row key={row.id} row={row} />)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Section>
            </Loadable>
        );
    }
}

const Row = props => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const capitalizedCategory = formatter.capitalizeFirstLetter(row.category);
    const capitalizedAction = formatter.capitalizeFirstLetter(row.action);
    const formattedEventString = `${capitalizedCategory.match(stringSplitRegex).join(" ")}, ${capitalizedAction.match(stringSplitRegex).join(" ")}`;
    const rowProps = getRowProps(row);
    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell><UserProfile profile={row} /></TableCell>
                <TableCell>{formattedEventString}</TableCell>
                <TableCell>{row.ipaddress}</TableCell>
                <TableCell>{formatDate(row.time)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Associated Data
                            </Typography>
                            <p>{format(rowProps)}</p>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

import React, { Component } from "react";
import styled from "styled-components";
import { DocumentMenuBar } from "legacy-js/editor/DocumentEditor/Components/DocumentMenuBar";
import { docEditorState, DocumentEditorController } from "legacy-js/editor/DocumentEditor/DocumentEditorController";
import { Gap10, Gap20, Gap30 } from "legacy-js/react/components/Gap";
import { ScrollBox } from "legacy-js/react/components/LayoutGrid";
import { CollaborationBar } from "legacy-js/editor/DocumentEditor/Components/CollaborationBar";
import PageEditorPanel from "legacy-js/editor/DocumentEditor/Components/PageEditorPanel";
import { BlueButton } from "legacy-js/react/components/UiComponents";
import { CanvasWrapper } from "legacy-js/editor/PresentationEditor/Components/CanvasWrapper";
import AppController from "legacy-js/core/AppController";

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #4b4e55;
`;

const EditorContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 20px;
  padding: 0px 30px;
`;

const InnerFrame = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  padding-bottom: 30px;
  align-items: center;
`;

const CanvasContainer = styled.div`
  overflow-y: scroll;
  max-width: calc(1280px + var(--scrollbar-width));
  width: 100% ;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

class DocumentEditor extends Component {
    constructor() {
        super();
        this.canvasContainerRef = React.createRef();

        this.state = {
            isLoaded: false
        };
    }

    async componentDidMount() {
        await DocumentEditorController.loadDocument(this.props.documentId);

        window.addEventListener("resize", this.handleResize);
        this.handleResize();

        this.setState({ isLoaded: true });

        let scrollBarWidth = this.canvasContainerRef.current.scrollWidth - this.canvasContainerRef.current.clientWidth;
        // document.body.style.setProperty("--scrollbar-width", `${window.innerWidth - document.body.clientWidth}px`);
    }

    handleResize = () => {
        let width = this.canvasContainerRef.current.offsetWidth;
        let scale = width / this.props.pageWidth;
        DocumentEditorController.setPageScale(scale);
    }

    render() {
        const { document, currentPage } = this.props;
        const { isLoaded } = this.state;

        return (
            <Container>
                <DocumentMenuBar />
                <EditorContainer>
                    {/*<PageEditorPanel />*/}
                    <InnerFrame>
                        <CanvasContainer ref={this.canvasContainerRef}>
                            <Gap10 />
                            {isLoaded && document.slides.map(model => (
                                <CanvasWrapper
                                    key={model.id}
                                    slideModel={model}
                                    current={model == currentPage}
                                />
                            ))}
                            <Gap10 />
                        </CanvasContainer>
                    </InnerFrame>
                    {/*<CollaborationBar />*/}
                </EditorContainer>
            </Container>
        );
    }
}

export default AppController.withState(
    docEditorState.withState(
        DocumentEditor
    )
);

import { RowGrid } from "legacy-js/react/components/LayoutGrid";
import React, { Component } from "reactn";
import {
    BeautifulDialog,
    DialogContent
} from "legacy-js/react/components/Dialogs/BaseDialog";
import {
    Button,
    DialogActions,
    DialogTitle,
    TextField
} from "@material-ui/core";
import { Gap10, Gap30 } from "legacy-js/react/components/Gap";

export class ValidatedDeleteDialog extends Component {
    state = {
        inputValidation: ""
    };

    render() {
        let { title, message, validateStr, prompt } = this.props;
        let { inputValidation } = this.state;

        return (
            <BeautifulDialog
                classes={{ paper: "error-dialog" }}
                closeDialog={() => this.handleAction(false)}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    {message}
                    <Gap30 />
                    <div style={{ color: "orangered" }}>
                        {prompt}
                    </div>
                    <Gap10 />
                    <RowGrid>
                        <TextField
                            label=""
                            placeholder={validateStr}
                            value={inputValidation}
                            onChange={e => {
                                this.setState({
                                    inputValidation: e.currentTarget.value
                                });
                            }}
                        />
                    </RowGrid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.handleAction(false)}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => this.handleAction(true)}
                        color="primary"
                        disabled={inputValidation !== validateStr}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }

    handleAction(isValidated) {
        this.props.closeDialog();
        this.props.callback(isValidated);
    }
}

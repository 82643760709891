import React, { Component, Fragment } from "reactn";
import { Gap10, Gap20 } from "legacy-js/react/components/Gap";
import { BlueSwitch } from "legacy-js/react/components/Switch.js";
import { app } from "js/namespaces";
import {
    BlueButton,
    Section,
    UIPane,
    UIPaneContents,
    UIPaneHeader
} from "legacy-js/react/components/UiComponents";
import { Snackbar } from "@material-ui/core";

class PreferencesPane extends Component {
    constructor() {
        super();
        this.state = {
            allowSpellChecking: !app.user.get("ignoreSpellcheck"),
        };
    }

    handleSpellcheckChange = () => {
        this.setState(
            { allowSpellChecking: !this.state.allowSpellChecking },
            () => {
                app.user.update({
                    ignoreSpellcheck: !this.state.allowSpellChecking
                });
            }
        );
    };

    render() {
        const { allowSpellChecking } = this.state;
        return (
            <UIPane>
                <UIPaneHeader>Preferences</UIPaneHeader>
                <UIPaneContents>
                    <Section title="Spellcheck">
                        <div className="content-wrapper">
                            <div className="input-column spellcheck">
                                <BlueSwitch
                                    checked={allowSpellChecking}
                                    onChange={() =>
                                        this.handleSpellcheckChange(
                                            "allowSpellChecking"
                                        )
                                    }
                                    label="Check Spelling As You Type"
                                />
                            </div>
                        </div>
                    </Section>
                </UIPaneContents>
            </UIPane>
        );
    }
}

export default PreferencesPane;

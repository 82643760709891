import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { formatter } from "js/core/utilities/formatter";
import { app } from "js/namespaces";
import { darken } from "@material-ui/core/styles/colorManipulator";
import { FeatureType } from "js/core/models/features";
import { UpgradePlanDialogType } from "legacy-js/react/views/MarketingDialogs/UpgradePlanDialog";
import { ShowDialog, ShowUpgradeDialog } from "./Dialogs/BaseDialog";
import CheckoutDialog from "legacy-js/react/views/UserOptions/Billing/CheckoutDialog";
import { openPricingPage } from "legacy-js/core/utilities/externalLinks";

const styles = {
    root: {
        backgroundColor: "#fa0",
        marginLeft: "20px",
        marginRight: "10px",
        height: "30px",
        padding: "0 12px 0 9px",
        color: "#fff",
        fontWeight: "600",
        fontSize: "13px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textTransform: "uppercase",
        borderRadius: 3,
        "&:hover": {
            backgroundColor: darken("#FFAA00", 0.1)
        }
    }
};

class UpgradeButton extends Component {
    handleClick(status, isPro) {
        const { workspaceId } = this.props;
        if (workspaceId === "personal") {
            if (status === "trial_ended" || status === "trialing") {
                ShowDialog(CheckoutDialog);
            } else if (!isPro) {
                const props = {
                    cta: "Menubar",
                };
                openPricingPage("basic", props);
            }
        } else {
            ShowUpgradeDialog({
                type: UpgradePlanDialogType.UPGRADE_PLAN,
                analytics: { cta: "Menubar" },
                workspaceId
            });
        }
    }

    render() {
        const isPro = !app.user.features.isFeatureEnabled(FeatureType.UPGRADE, this.props.workspaceId);

        let buttonText;
        if (this.props.workspaceId == "personal") {
            const { status, trialEndsTimestamp } = app.user.trialStatus || {};
            if (status === "trial_ended" || status === "trialing") {
                const endsVerb = status === "trial_ended" ? "ended" : `ends ${formatter.formatTimestamp(trialEndsTimestamp)}`;
                buttonText = `Upgrade now - Trial ${endsVerb}`;
            } else if (!isPro) {
                buttonText = "Upgrade";
            }
        } else {
            if (!isPro) {
                buttonText = "Upgrade";
            }
        }

        return !app.isConstrained && buttonText ? (
            <Button
                className={this.props.classes.root}
                onClick={() => this.handleClick(status, isPro)}
            >
                <i className="micon" style={{ fontSize: 20, verticalAlign: "top", position: "relative", left: ".5px" }}>new_releases</i>
                <label>{buttonText}</label>
            </Button>
        ) : null;
    }
}

export default withStyles(styles)(UpgradeButton);

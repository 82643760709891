import React from "react";
import {
    Section,
    UIPane,
    UIPaneContents,
    UIPaneHeader,
    BlueButton,
} from "legacy-js/react/components/UiComponents";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { IconButton } from "@material-ui/core";
import * as social from "js/core/utilities/social";
import { sanitizeSvg } from "js/core/utilities/dompurify";
import Api from "js/core/api";
import { enableTestingApi } from "legacy-js/config";
import Spinner from "legacy-js/react/components/Spinner";
import { withFirebaseUser } from "legacy-js/react/views/Auth/FirebaseUserContext";
import ReferralEmailDialog from "legacy-js/react/views/UserOptions/dialogs/ReferralEmailDialog";
import { renderReactDialog } from "legacy-js/react/renderReactRoot";
import { trackActivity } from "js/core/utilities/utilities";
import VerifyEmailDialog from "legacy-js/react/views/MarketingDialogs/VerifyEmailDialog";
import { ClipboardType, clipboardWrite } from "js/core/utilities/clipboard";
import "css/billing.scss";

class ReferralsPane extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "loading",
            didCopyLink: false
        };
    }

    componentDidMount() {
        Api.referrals.get()
            .then(data => {
                this.setState({ status: "loaded", referrals: data });
            });
    }

    affiliateUrl() {
        const url = new URL("/signup?affiliate=" + this.props.firebaseUser.uid, window.location.href);
        return url.href;
    }

    handleEnterEmails = () => {
        if (this.props.firebaseUser.emailVerified) {
            renderReactDialog(ReferralEmailDialog);
        } else {
            renderReactDialog(VerifyEmailDialog);
        }
    };

    handleCopyLink = () => {
        clipboardWrite({
            [ClipboardType.TEXT]: this.affiliateUrl(),
        });
        this.setState({ didCopyLink: true });

        setTimeout(() => {
            this.setState({ didCopyLink: false });
        }, 2000);

        trackActivity("Referral", "CopyLink", null, null, {});
    };

    handleSocialShare = network => () => {
        social.createReferralPopup(network, this.affiliateUrl());

        trackActivity("Referral", "SocialShare", null, null, {
            type: network
        });
    }

    handleAddCreditNow = async () => {
        // for QA and dev, only available when testing api is enabled
        document.body.style.cursor = "progress";
        const res = await fetch("/testing/add-referral-credit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({ email: this.props.firebaseUser.email })
        });
        document.body.style.cursor = "default";
        const data = await res.json();
        alert("Result = " + JSON.stringify(data));
    };

    render() {
        return (
            <UIPane className="billing">
                <UIPaneHeader>Referrals</UIPaneHeader>
                <UIPaneContents>
                    <Section title="Share your secret weapon">
                        <div style={{ marginBottom: 10, fontSize: "16px" }}>
                            Introduce friends and peers to Beautiful.ai, and for each new subscription to our Pro plan,
                            we’ll automatically apply a <b>$10 credit</b> toward your account.
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <a target="_blank" href="/referral-terms">Read the terms</a>
                        </div>
                        <FlexBox horizontalAlign="left" verticalAlign="top">
                            <div style={{ marginRight: 30 }}>
                                <div className="gdrive-notice-text" style={{ textTransform: "uppercase", fontSize: "90%", marginBottom: 10, width: "auto" }}>
                                    Invite via Email
                                </div>
                                <BlueButton
                                    onClick={this.handleEnterEmails}
                                >Enter Emails
                                </BlueButton>
                            </div>
                            <div style={{ marginRight: 30 }}>
                                <div className="gdrive-notice-text" style={{ textTransform: "uppercase", fontSize: "90%", marginBottom: 10, width: "auto" }}>
                                    Invite via Link
                                </div>
                                <BlueButton
                                    onClick={this.handleCopyLink}
                                    data-test-url={this.affiliateUrl()}
                                >Copy Link
                                </BlueButton>
                                {this.state.didCopyLink && (
                                    <div className="gdrive-notice-text" style={{ fontSize: "90%", marginTop: -1, position: "absolute", width: "auto" }}>
                                        ✓ Copied
                                    </div>
                                )}
                            </div>
                            <div style={{ marginRight: 30 }}>
                                <div className="gdrive-notice-text" style={{ textTransform: "uppercase", fontSize: "90%", marginBottom: 2, width: "auto" }}>
                                    Share on Social
                                </div>
                                {social.networks.map(network => (
                                    <IconButton
                                        key={network.name}
                                        style={{ marginRight: 10 }}
                                        onClick={this.handleSocialShare(network.name)}
                                    >
                                        <span
                                            style={{ width: 28, height: 28, fill: "#5a5a5a" }}
                                            dangerouslySetInnerHTML={{
                                                __html: sanitizeSvg(network.icon)
                                            }}
                                        />
                                    </IconButton>
                                ))}
                            </div>
                        </FlexBox>
                    </Section>
                    <Section title="Your referrals">
                        {this.state.status === "loading"
                            ? <Spinner />
                            : (<>
                                <div className="billing-blocks">
                                    <div className="billing-block referral referral-completed">
                                        <div className="actions" style={{ fontSize: 36 }}>{this.state.referrals.total}</div>
                                        <div className="title">Completed Referrals</div>
                                    </div>
                                    <div className="billing-block referral referral-credit-earned">
                                        <div className="actions" style={{ fontSize: 36 }}>${this.state.referrals.totalAmount / 100}</div>
                                        <div className="title">Credit Earned</div>
                                    </div>
                                    <div
                                        className="billing-block referral referral-credit-available"
                                        onClick={event => {
                                            // for QA and dev, only avaialble when testing api is enabled
                                            if (enableTestingApi && event.shiftKey) {
                                                this.handleAddCreditNow();
                                            }
                                        }}
                                    >
                                        <div className="actions" style={{ fontSize: 36 }}>${this.state.referrals.totalAvailable / 100}</div>
                                        <div className="title">Credit Available</div>
                                    </div>
                                </div>
                            </>
                            )}
                    </Section>
                </UIPaneContents>
            </UIPane>
        );
    }
}

export default withFirebaseUser(ReferralsPane);

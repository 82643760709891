import React, { Component } from "react";

import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

import { CustomerType } from "legacy-common/constants";
import Api from "js/core/api";
import getLogger, { LogGroup } from "js/core/logger";
import { formatter } from "js/core/utilities/formatter";
import { Gap20 } from "legacy-js/react/components/Gap";
import Loadable from "legacy-js/react/components/Loadable";
import { BeautifulDialog, ShowErrorDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import withStripe from "legacy-js/react/views/UserOptions/Billing/withStripe";
import { handlePaymentIntentResult } from "js/core/services/stripe";

import { Discount } from "../components/NextPaymentSection";

const logger = getLogger(LogGroup.BILLING);

export default withStripe(class ChangePlanDialog extends Component {
    static get type() {
        return "ChangePlan";
    }

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            interval: this.props.nextInterval == "year" ? "month" : "year",
            displayInterval: this.props.nextInterval == "year" ? "monthly" : "annual",
            invoiceTotal: 0,
            promoError: null,
        };
    }

    async componentDidMount() {
        const { body: invoice } = await Api.invoices.post({
            type: "preview_upcoming_product",
            orgId: this.props.orgId, // API will use user.uid if undefined
            prorate: this.state.interval === "year", // only month -> year is prorated
            excludeTrial: true, // plans still in trial only see a $0 "Trial period for xyz" invoice, avoid that
            updates: {
                subscription_items: [
                    {
                        baiProduct: {
                            name: this.props.subscription.product,
                            type: this.state.interval
                        },
                        quantity: this.props.subscription.items.data[0].quantity
                    }
                ],
            }
        });

        // yearly -> monthly changes don't happen immediately, but at the end of the year
        let invoiceTotal = invoice.total;
        if (invoice.discount && this.state.interval === "month" && invoice.discount.end > invoice.period_end) {
            // remove discount if it will have ended by the end of the year
            invoiceTotal += invoice.total_discount_amounts[0].amount;
        }

        this.setState({
            preDiscountPrice: invoiceTotal / 100,
            invoiceTotal: invoiceTotal / 100,
            isLoading: false
        });
    }

    handleConfirm = async () => {
        const { callback, orgId, closeDialog, subscription, stripe } = this.props;
        const { promo, interval } = this.state;

        this.setState({ isLoading: true });

        const payload = {
            type: "switch_plan",
            customerType: orgId === undefined ? CustomerType.INDIVIDUAL : CustomerType.TEAM,
            orgId,
            planName: subscription.product,
            productType: interval,
            promoCouponId: promo?.promoCouponId
        };
        try {
            if (promo) {
                const response = await Api.promos.put({
                    promoCouponId: promo.promoCouponId,
                    subscription: subscription.id,
                    action: "apply"
                });

                if (response.error) {
                    throw new Error(response.error);
                }
            }

            const { body: paymentIntentResult } = await Api.subscriptions.put(payload);
            await handlePaymentIntentResult(stripe, paymentIntentResult);

            callback && callback();
            closeDialog();
        } catch (err) {
            logger.error(err, "Api.subscriptions.put() failed", payload);

            ShowErrorDialog({
                error: `Unable to switch to ${this.state.displayInterval} billing.`,
                message: "Something unexpected occurred.",
            });
        }
    };

    renderMessage = () => {
        const { subscription } = this.props;
        const currentPeriodEnd = formatter.formatTimestamp(subscription.current_period_end, true);
        const invoiceTotal = this.state.invoiceTotal;

        if (subscription.status === "trialing") {
            return (
                <>
                    Your trial will be continued. Your next
                    bill of <strong>${invoiceTotal.toFixed(2)}</strong> will be due on <strong>{currentPeriodEnd}</strong>.
                </>
            );
        } else {
            if (this.state.interval === "month") {
                return (
                    <>
                        You will be billed <strong>${invoiceTotal.toFixed(2)}</strong>/month when your yearly plan ends on <strong>{currentPeriodEnd}</strong>.
                    </>
                );
            } else if (subscription.schedule && subscription.schedule.status === "active") {
                // user is on yearly but has already scheduled to switch to monthly
                const phases = subscription.schedule.phases;
                const lastPhase = phases[phases.length - 1];
                if (lastPhase.plans[0].plan.interval === this.props.nextInterval) {
                    return (
                        <>
                            You will remain on annual billing instead of switching to monthly.
                        </>
                    );
                }
            } else {
                return (
                    <>
                        You will be billed <strong>${this.state.invoiceTotal.toFixed(2)}</strong> today, including a proration for the current month.
                    </>
                );
            }
        }
    };

    render() {
        const { isLoading, preDiscountPrice, displayInterval, interval, promoError } = this.state;
        const { subscription, closeDialog } = this.props;

        const isRegularPricing = subscription.product === "pro" || subscription.product === "team";

        return (
            <BeautifulDialog closeDialog={closeDialog}>
                <DialogTitle>Switch to {displayInterval} billing?</DialogTitle>
                <DialogContent>
                    <Loadable isLoading={isLoading}>
                        <DialogContentText>
                            {this.renderMessage()}
                        </DialogContentText>
                        {
                            ((subscription.interval === "month" && isRegularPricing) || promoError) && (
                                <Discount
                                    subscription={subscription}
                                    error={promoError}
                                    nextInterval={interval}
                                    handleOnClear={() => {
                                        // reset invoice total to initial invoice total because we are removing the value of the invoice
                                        // once we apply the promo, but we want to keep the initial invoice total to calculate the new total
                                        // if the promo is removed
                                        this.setState({
                                            promoError: null,
                                            invoiceTotal: preDiscountPrice
                                        });
                                    }}
                                    callback={promo => {
                                        let totalAmount = 0;
                                        // if a user is in trial use full amount, if not, use the amount paid and deduct the promo amount from the total amount
                                        // because client has already paid for the full month

                                        if (promo.percentOff) {
                                            totalAmount = this.state.invoiceTotal - (this.state.invoiceTotal * (promo.percentOff / 100));
                                        } else if (promo.amountOff) {
                                            totalAmount = this.state.invoiceTotal - (promo.amountOff / 100).toFixed(2);
                                        }

                                        // force render of new invoice total
                                        this.setState({
                                            promo,
                                            invoiceTotal: totalAmount
                                        });

                                        return promo;
                                    }}
                                />
                            )
                        }
                    </Loadable>
                    <Gap20 />
                    <DialogActions>
                        <Button onClick={() => closeDialog()}>
                            Cancel
                        </Button>
                        <Button onClick={this.handleConfirm} color="primary">
                            Confirm
                        </Button>
                    </DialogActions>
                </DialogContent>
            </BeautifulDialog>
        );
    }
});

import React from "react";
import { getStaticUrl } from "../../../../config";
import Icon from "../../../components/Icon";

export const PaymentMethod = ({ brand, card_name, exp_month, exp_year }) => {
    return (
        <div className="payment-method">
            <div className="card-icon">
                <CardBrandIcon brand={brand} />
            </div>
            <div className="card-info">
                <div className="card-name">{card_name}</div>
                <div className="card-expiration">
                    Expires {`${exp_month}/${exp_year}`}
                </div>
            </div>
        </div>
    );
};

export const Last4DigitsPaymentMethod = ({ brand, last4 }) => {
    return (
        <div className="payment-method">
            <div className="card-icon">
                <CardBrandIcon brand={brand} />
            </div>
            <div className="card-info">
                <div className="last-4-card-name">{brand} ending in {last4}</div>
            </div>
        </div>
    );
};

export const CardBrandIcon = ({ brand }) => {
    const iconMap = {
        amex: 22,
        diners: 10,
        discover: 14,
        jcb: 16,
        mastercard: 2,
        visa: 1
    };

    const iconNumber = iconMap[brand];
    let icon = null;

    if (iconNumber) {
        const src = getStaticUrl(`/images/card_brand_icons/${iconNumber}.png`);
        icon = <img src={src} className="card-brand-icon" />;
    } else {
        icon = <Icon iconName="credit_card" />;
    }
    return icon;
};

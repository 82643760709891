import React, { Component } from "react";
import styled from "styled-components";
import { BlockLibrary } from "legacy-js/editor/DocumentEditor/Components/BlockLibrary";
import { ColorPicker } from "legacy-js/react/components/ColorPicker";
import { LabeledContainer } from "legacy-js/react/components/LabeledContainer";
import { Gap20 } from "legacy-js/react/components/Gap";
import { docEditorState } from "legacy-js/editor/DocumentEditor/DocumentEditorController";
import { BackgroundStyleType } from "legacy-common/constants";

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: rgb(51, 51, 51);
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  color: white;
`;

class PageEditorPanel extends Component {
    handleChangePageColor = color => {
        const { currentCanvas } = this.props;

        currentCanvas.model.layout.backgroundColor = color;
        currentCanvas.model.layout.backgroundStyle = BackgroundStyleType.COLOR;
        currentCanvas.updateCanvasModel(true);
    }

    render() {
        return (
            <Container>
                <LabeledContainer label="Page Color">
                    <ColorPicker onChange={this.handleChangePageColor} />
                </LabeledContainer>
                <Gap20/>
                <BlockLibrary />
            </Container>
        );
    }
}

export default docEditorState.withState(PageEditorPanel);

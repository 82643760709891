import React, { Component } from "react";
import { DialogTitle, DialogActions } from "@material-ui/core";
import { AddressElement } from "@stripe/react-stripe-js";

import { BeautifulDialog, DialogContent } from "legacy-js/react/components/Dialogs/BaseDialog";
import { BlueButton, BlueOutlinedButton } from "legacy-js/react/components/UiComponents";
import FetchingClickShield from "legacy-js/react/components/FetchingClickShield";
import withElements from "legacy-js/react/views/UserOptions/Billing/withElements";
import withStripe from "legacy-js/react/views/UserOptions/Billing/withStripe";

class UpdateBillingAddressDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: props.name || "",
            address: { ...(props.address || {}) },
            isAddressValid: !!props.address,
            fetching: false
        };
    }

    handleAddressChange = ({ complete, value: { address, name } }) => {
        this.setState({ address: { ...address }, isAddressValid: complete, name });
    }

    handleUpdate = async () => {
        const { onAccept, closeDialog } = this.props;
        const { address, name } = this.state;

        this.setState({ fetching: true });

        // onAccept should never throw
        await onAccept({ address, name });

        closeDialog();
    }

    render() {
        const { hideBackdrop, closeDialog, title = "Billing address", canCancel = true, isOrganization = false } = this.props;
        const { fetching, address, isAddressValid, name } = this.state;

        return (
            <BeautifulDialog
                hideBackdrop={hideBackdrop}
                closeDialog={() => { }}
            >
                <DialogTitle>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <FetchingClickShield visible={fetching} backgroundColor="rgba(255,255,255,0.5)" />
                    <AddressElement
                        options={{
                            mode: "billing",
                            defaultValues: {
                                name,
                                address
                            },
                            display: {
                                name: isOrganization ? "organization" : "full"
                            }
                        }}
                        onChange={this.handleAddressChange}
                    />
                </DialogContent>
                <DialogActions>
                    {canCancel && <BlueOutlinedButton disabled={fetching} onClick={closeDialog}>
                        Cancel
                    </BlueOutlinedButton>}
                    <BlueButton
                        disabled={!isAddressValid || fetching}
                        onClick={this.handleUpdate}>
                        Update
                    </BlueButton>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}

export default withStripe(withElements(UpdateBillingAddressDialog));

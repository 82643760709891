import React, { Component } from "react";
import moment from "moment";
import { Select, MenuItem, Tooltip } from "@material-ui/core";
import styled from "styled-components";

import { _ } from "legacy-js/vendor";
import { app } from "js/namespaces";
import getLogger, { LogGroup } from "js/core/logger";
import Api from "js/core/api";
import { download, trackActivity } from "js/core/utilities/utilities";
import { getUsedSeatCount } from "legacy-js/react/views/UserOptions/UserOptionsContainer";
import { TEAM_USER_ROLES, TEAM_USER_LICENSE_STATUS, AnalyticsRolesAndLicensesMap } from "legacy-common/constants";
import { ds } from "js/core/models/dataService";
import { ClipboardType, clipboardWrite } from "js/core/utilities/clipboard";

import { List as MembersList, ListAction, ListField } from "legacy-js/react/components/List";
import {
    ShowErrorDialog,
    ShowDialog,
    ShowMessageDialog,
    ShowConfirmationDialog,
    ShowSnackBar
} from "legacy-js/react/components/Dialogs/BaseDialog";
import AddTeamSeatDialog from "legacy-js/react/views/UserOptions/dialogs/AddTeamSeatDialog";
import { UserProfile } from "legacy-js/react/components/Avatar";
import { ResendInviteDialog } from "legacy-js/react/components/InviteInput/ResendInviteDialog";
import { Divider, BlueButton } from "legacy-js/react/components/UiComponents";
import { FeatureType } from "legacy-common/features";
import { UIController } from "legacy-js/editor/dialogs/UIController";
import { isOwnerOrLibrarian } from "legacy-common/utils/roles";

const logger = getLogger(LogGroup.TEAMS);

const StyledField = styled.div`
    text-transform: uppercase;
    font-size: 13px;
    color: #555;
`;

const DisabledTag = styled.div`
    text-transform: uppercase;
    font-size: 12px;
    color: #484747;
    background-color: #ffd8d8;
    padding: 5px;
    width: 72px;
    text-align: center;
    font-weight: 600;
`;

function getRoleUpdateText(user, role) {
    const text = { title: "", message: "" };
    switch (role) {
        case (TEAM_USER_ROLES.MEMBER):
        case (TEAM_USER_ROLES.MEMBER_LICENSED):
            text.title = `Do you want to change ${user.displayName || user.email} to a member?`;
            text.message = "They will only have access to shared team content.";
            break;
        case (TEAM_USER_ROLES.LIBRARIAN):
            text.title = `Do you want to change ${user.displayName || user.email} to a librarian?`;
            text.message = "They will be able add and manage slide, presentations, themes, and assets for the team.";
            break;
        case (TEAM_USER_ROLES.OWNER):
            text.title = `Do you want to change ${user.displayName || user.email} to a owner?`;
            text.message = "They will be able to manage payment info, add/remove members, add/remove seats, and change team permissions on resources.";
            break;
    }
    return text;
}

class TeamMembersList extends Component {
    refreshApp = () => {
        setTimeout(() => {
            window.location.href = "/";
        }, 50);
    };

    handleRemoveInvite = async email => {
        const { team, getTeam } = this.props;
        try {
            await Api.teams.put({
                teamId: team.id,
                type: "remove_invites",
                emails: [email]
            });
            trackActivity("Organization", "InviteRemoved", null, null, { email }, { audit: true });
            getTeam();
        } catch (err) {
            if (err) {
                logger.error(err, "[TeamMembersList] handleRemoveInvite() failed", { teamId: team.id });
                ShowErrorDialog({
                    error: "Failed to remove invite",
                    message: "Something went wrong. Please try again and reach out to support@beautiful.ai if the issue persists."
                });
            }
        }
    };

    handleRoleUpdate = async (user, role) => {
        const { org, team, getTeam } = this.props;

        const { title, message } = getRoleUpdateText(user, role);
        const accepted = await ShowConfirmationDialog({
            title,
            message
        });

        if (!accepted) {
            return;
        }

        try {
            await Api.teams.put({
                teamId: team.id,
                orgId: org.id,
                type: "update_roles",
                userIds: [user.id],
                role
            });

            const props = {
                workspace_id: org.id,
                affected_user: user.id,
                old_role: AnalyticsRolesAndLicensesMap[user.role],
                new_role: AnalyticsRolesAndLicensesMap[role],
                old_license: AnalyticsRolesAndLicensesMap[user.license],
                new_license: AnalyticsRolesAndLicensesMap[user.license]
            };
            trackActivity("Organization", "UserStatusChanged", null, null, props, { audit: true });

            getTeam();
        } catch (err) {
            if (err) {
                logger.error(err, "[TeamMembersList] handleRoleUpdate() failed", { teamId: team.id, role, uid: user.id });
                ShowErrorDialog({
                    error: `Failed to update user to ${role}`,
                    message: "Something went wrong. Please try again and reach out to support@beautiful.ai if the issue persists."
                });
            }
        }
    };

    handleUpgradeMember = async user => {
        const { team, getTeam, getFreshState, org, isValidSubscription, totalSeatCount } = this.props;
        const usedSeatCount = getUsedSeatCount(team.members);

        //If there are not enough seats show AddTeamSeatDialog
        if (totalSeatCount - usedSeatCount <= 0) {
            if (isValidSubscription) {
                ShowDialog(AddTeamSeatDialog, {
                    userId: user.id,
                    team,
                    org,
                    teamName: team.name,
                    teamMemberCount: team.members.length,
                    usedSeatCount,
                    currentSeatCount: totalSeatCount,
                    callback: () => getFreshState()
                });
            } else {
                ShowErrorDialog({ title: "Unable to add seats", message: "Seems like you cancelled the team subscription." });
            }
            return;
        }

        //If there are enough seats and the team is a pro-team assign a seat to a user
        try {
            if (user.pending) {
                await Api.teams.put({
                    orgId: org.id,
                    teamId: team.id,
                    type: "change_pending_license",
                    emails: [user.email],
                    license: TEAM_USER_LICENSE_STATUS.TEAM_PRO,
                });
            } else {
                await Api.teams.put({
                    orgId: org.id,
                    teamId: team.id,
                    type: "add_user_to_seat",
                    userIds: [user.id]
                });
            }

            const props = {
                workspace_id: org.id,
                affected_user: user.id,
                old_role: AnalyticsRolesAndLicensesMap[user.role],
                new_role: AnalyticsRolesAndLicensesMap[user.role],
                old_license: AnalyticsRolesAndLicensesMap["free"],
                new_license: AnalyticsRolesAndLicensesMap["team_pro"]
            };
            trackActivity("Organization", "UserStatusChanged", null, null, props, { audit: true });

            getTeam();
            if (user.id === app.user.id) {
                ShowMessageDialog({
                    title: "Your seat has been added.",
                    message: "Your browser will be refreshed for the change to take effect.",
                    onClick: this.refreshApp,
                    onClose: this.refreshApp
                });
            }
        } catch (err) {
            if (err) {
                logger.error(err, "[TeamMembersList] handleUpgradeMember() failed", { teamId: team.id, uid: user.id });
                ShowErrorDialog({
                    error: "Failed to upgrade user",
                    message: "Something went wrong. Please try again and reach out to support@beautiful.ai if the issue persists."
                });
            }
        }
    };

    handleDowngradeMember = async user => {
        const { team, getTeam, org } = this.props;

        ShowConfirmationDialog({
            title: "Are you sure?",
            message: `By downgrading to a free team member, ${user.id === app.user.id ? "You" : (user.displayName || user.email) } will lose access to Pro features and your team’s shared resources, including themes, assets, and templates. The vacant Team Pro seat will be available to assign to another member.`
        }).then(async accept => {
            if (!accept) {
                return;
            }
            try {
                if (user.pending) {
                    await Api.teams.put({
                        orgId: org.id,
                        teamId: team.id,
                        type: "change_pending_license",
                        emails: [user.email],
                        license: TEAM_USER_LICENSE_STATUS.FREE,
                    });
                } else {
                    await Api.teams.put({
                        orgId: org.id,
                        teamId: team.id,
                        type: "remove_user_from_seat",
                        userIds: [user.id]
                    });
                }

                const props = {
                    workspace_id: org.id,
                    affected_user: user.id,
                    old_role: AnalyticsRolesAndLicensesMap[user.role],
                    new_role: AnalyticsRolesAndLicensesMap[user.role],
                    old_license: AnalyticsRolesAndLicensesMap["team_pro"],
                    new_license: AnalyticsRolesAndLicensesMap["free"]
                };
                trackActivity("Organization", "UserStatusChanged", null, null, props, { audit: true });

                getTeam();
                if (user.id === app.user.id) {
                    ShowMessageDialog({
                        title: "Your seat has been removed.",
                        message: "Your browser will be refreshed for the change to take effect.",
                        onClick: this.refreshApp,
                        onClose: this.refreshApp
                    });
                }
            } catch (err) {
                if (err) {
                    logger.error(err, "[TeamMembersList] handleDowngradeMember() failed", { teamId: team.id, uid: user.id });
                    ShowErrorDialog({
                        error: "Failed to downgrade user",
                        message: "Something went wrong. Please try again and reach out to support@beautiful.ai if the issue persists."
                    });
                }
            }
        });
    };

    getMemberPlan = member => {
        if (member.pending) {
            return member.license;
        }

        if (ds.teams.defaultTeamForOrg(this.props.organizationId).checkIfUserHasSeat(member)) {
            return "team_pro";
        }

        return "free";
    };

    renderLicenseDropdown = member => {
        const {
            canManageTeam,
            team,
            getTeam,
            isDefaultTeam,
            handleRemoveMember,
            canAddSeats
        } = this.props;

        const canChangeLicense = (
            !isOwnerOrLibrarian(member.role) &&
            (
                member.role !== TEAM_USER_ROLES.MEMBER ||
                canAddSeats
            )
        );

        const showRemove = isDefaultTeam &&
            app.user.getEmail() !== member.email;

        const isDisabled = !canManageTeam ||
            app.user.id === member.id ||
            (!canChangeLicense && !showRemove);

        if (!member.license) {
            return "None";
        }

        const handleChange = event => {
            switch (event.target.value) {
                case TEAM_USER_LICENSE_STATUS.TEAM_PRO:
                    this.handleUpgradeMember(member);
                    break;
                case TEAM_USER_LICENSE_STATUS.FREE:
                    this.handleDowngradeMember(member);
                    break;
                case "remove":
                    if (member.pending) {
                        this.handleRemoveInvite(member.email);
                    } else {
                        handleRemoveMember(
                            team,
                            member,
                            team.members,
                            "manage",
                            getTeam,
                            isDefaultTeam
                        );
                    }
                    break;
            }
        };

        return (
            <Select value={member.license}
                onChange={handleChange}
                disabled={isDisabled}
                disableUnderline
            >
                <MenuItem
                    key={TEAM_USER_LICENSE_STATUS.TEAM_PRO}
                    value={TEAM_USER_LICENSE_STATUS.TEAM_PRO}
                    disabled={!canChangeLicense}
                >Team Pro</MenuItem>
                <MenuItem
                    key={TEAM_USER_LICENSE_STATUS.FREE}
                    value={TEAM_USER_LICENSE_STATUS.FREE}
                    disabled={!canChangeLicense}
                >Free</MenuItem>
                {
                    showRemove &&
                    <Divider margin={0} />
                }
                {
                    showRemove &&
                    <MenuItem
                        key="remove"
                        value="remove"
                    >Remove Member</MenuItem>
                }
            </Select>
        );
    }

    renderRoleDropdown = member => {
        const { canManageTeam } = this.props;

        const isDisabled = (
            app.user.id === member.id ||
            member.license === "free" ||
            member.pending
        );

        const options = [
            {
                label: member.license === "free" ? "Member" : "Team Member",
                value: member.license === "free" ? TEAM_USER_ROLES.MEMBER : TEAM_USER_ROLES.MEMBER_LICENSED,
                isDisabled
            },
            { label: "Librarian", value: TEAM_USER_ROLES.LIBRARIAN, isDisabled },
            { label: "Owner", value: TEAM_USER_ROLES.OWNER, isDisabled }
        ];

        return (
            <Select value={member.role}
                onChange={event => this.handleRoleUpdate(member, event.target.value)}
                disabled={!canManageTeam || isDisabled}
                disableUnderline
            >
                {options.map(option => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
            </Select>
        );
    }

    sanitizeMembersForUI = team => {
        return team.members.map(member => {
            return {
                id: member.id,
                url: member.url,
                displayName: member.displayName ?? member.email,
                email: member.email,
                photoURL: member.photoURL,
                role: member.role,
                license: this.getMemberPlan(member),
                pending: !!member.pending,
                lastActive: member.lastActive,
                disabled: member.disabled
            };
        });
    }

    handleResendInvite = member => {
        const {
            team,
        } = this.props;
        const email = member.email;
        const license = member.license;

        ShowDialog(ResendInviteDialog, {
            user: member,
            onSendEmail: async () => {
                await Api.teams.put({
                    teamId: team.id,
                    type: "add_users",
                    members: [member],
                    orgId: team.orgId,
                });

                const props = {
                    team_id: team.id,
                    license,
                    recipient_email: email,
                    sender_email: app.user.getEmail(),
                };
                trackActivity("Organization", "ResendTeamInvite", null, null, props, { audit: true });
                ShowSnackBar({ message: `Invitation Resent to ${email}` });
            },
            onCopyInviteLink: async () => {
                const { teamInviteUrl } = await Api.teamInviteUrl.post({
                    teamId: team.id,
                    invitedUser: member,
                });

                await clipboardWrite({
                    [ClipboardType.TEXT]: teamInviteUrl,
                });

                const props = {
                    team_id: team.id,
                    license,
                    recipient_email: email,
                    sender_email: app.user.getEmail(),
                    teamInviteUrl,
                };
                trackActivity("Organization", "CopyTeamInviteLink", null, null, props, { audit: true });
                ShowSnackBar({ message: "Invitation Copied to Clipboard" });
            },
        });
    }

    // We are exporting the members list to a CSV file
    async downloadMembers(data) {
        const fieldList = ["displayName", "email", "role", "pending", "license", "lastActive"];

        const pickedData = data
            .map(member => _.pick(member, fieldList))
            .map(member => ({
                ...member,
                pending: member.pending ? "Pending Invite" : "",
                lastActive: member.lastActive ? moment(member.lastActive).format("YYYY-MM-DD HH:mm") : ""
            }));

        const convertToCSV = arr => {
            const array = [["Member", "Email", "Role", "Pending", "License", "lastActive"]].concat(arr);

            return array.map(it => {
                return Object.values(it).toString();
            }).join("\n");
        };

        download(`data:text/plain;charset=utf-8,${encodeURIComponent(convertToCSV(pickedData))}`, `members.csv`);
    }

    render() {
        const { team, isDefaultTeam, canManageTeam, scrollContainerEl } = this.props;

        const members = this.sanitizeMembersForUI(team);

        let isOwner = app.user.features.isFeatureEnabled(FeatureType.MANAGE_TEAM, UIController.getOrganizationId());

        return (
            <MembersList data={members} isOwner={isOwner}
                searchBarPlaceHolder="Search members..."
                exportToCSV={this.downloadMembers}
                showSearch
                searchFields={["displayName", "email"]}
                showHeader sort="displayName" scrollContainerEl={scrollContainerEl} >
                <ListField
                    field="displayName"
                    name="Member"
                    width="2fr"
                    type="bold"
                    sortable
                    renderer={member => <UserProfile profile={member} />}
                />
                {isDefaultTeam &&
                    <ListField
                        field="role"
                        name="Role"
                        isSelectField
                        sortable
                        renderer={this.renderRoleDropdown}
                    />}
                <ListField
                    field="pending"
                    sortable
                    renderer={member => {
                        if (!member.pending) {
                            return null;
                        }

                        return (<StyledField>
                            <span>pending invite</span>
                        </StyledField>);
                    }}
                />
                {isDefaultTeam &&
                    <ListField
                        field="license"
                        name="License"
                        sortable
                        isSelectField
                        renderer={this.renderLicenseDropdown}
                    />}
                <ListField
                    field="lastActive"
                    name="Last Active"
                    sortable
                    renderer={member => {
                        if (!member.lastActive) {
                            return (<StyledField>
                                <span>Never</span>
                            </StyledField>);
                        }

                        return (<div>{moment(member.lastActive).format("MMM D, YYYY")}</div>);
                    }}
                />
                {canManageTeam && <ListAction
                    width={160}
                    renderer={member => {
                        const getButton = () => {
                            if (!member.pending) {
                                return null;
                            }
                            if (isDefaultTeam && app.user.getEmail() === member.email) {
                                return null;
                            }

                            return (
                                <BlueButton
                                    className="resend-invite-button"
                                    onClick={() => this.handleResendInvite(member)}
                                >Resend Invite</BlueButton>
                            );
                        };

                        const getLabel = () => {
                            if (member.pending) {
                                return null;
                            }
                            if (!member.disabled) {
                                return null;
                            }

                            return (<Tooltip
                                title="The user was disabled via SCIM provisioning"
                            >
                                <DisabledTag>disabled</DisabledTag>
                            </Tooltip>);
                        };

                        return (<>
                            {getLabel()}
                            {getButton()}
                        </>);
                    }}
                />}
            </MembersList>
        );
    }
}

export default TeamMembersList;

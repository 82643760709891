import { Button } from "@material-ui/core";
import classNames from "classnames";
import moment from "moment";
import React, { Component, Fragment, setGlobal } from "reactn";
import styled, { css } from "styled-components";

import Api from "js/core/api";
import getLogger, { LogGroup } from "js/core/logger";
import { ds } from "js/core/models/dataService";
import * as googleAuth from "js/core/oauth/googleAuth";
import * as gDrive from "js/core/utilities/gDrive";
import { trackActivity } from "js/core/utilities/utilities";
import { app } from "js/namespaces";
import { CustomerType, SubscriptionStatus } from "legacy-common/constants";
import { FeatureType } from "legacy-common/features";
import { isPPTAddin } from "legacy-js/config";
import AppController from "legacy-js/core/AppController";
import {
    ShowConfirmationDialog,
    ShowDialog,
    ShowErrorDialog,
    ShowMessageDialog,
    ShowUpgradeDialog
} from "legacy-js/react/components/Dialogs/BaseDialog";
import { Gap10, Gap20 } from "legacy-js/react/components/Gap";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import Loadable from "legacy-js/react/components/Loadable";
import LogOutButton from "legacy-js/react/components/LogOutButton";
import Spinner from "legacy-js/react/components/Spinner";
import {
    BlueButton,
    ScaryButton,
    Section,
    UIPane,
    UIPaneContents,
    UIPaneHeader,
    YellowButton
} from "legacy-js/react/components/UiComponents";
import { withFirebaseAuth } from "legacy-js/react/views/Auth/FirebaseAuthContext";
import { withFirebaseUser } from "legacy-js/react/views/Auth/FirebaseUserContext";
import { ChangePasswordDialog } from "legacy-js/react/views/UserOptions/dialogs/ChangePasswordDialog";
import { ReauthPasswordDialog } from "legacy-js/react/views/UserOptions/dialogs/ReauthPasswordDialog";
import { ValidatedDeleteDialog } from "legacy-js/react/views/UserOptions/dialogs/ValidatedDeleteDialog";

import { auth } from "js/firebase/auth";
import { removeUrlsFromText } from "js/core/utilities/htmlTextHelpers";
import { getPricingPageUrl } from "js/core/utilities/pricing";
import { browserHistory } from "js/react/history";
import { openPricingPage } from "legacy-js/core/utilities/externalLinks";
import { GoogleButton } from "../../Auth/AuthUi";
import { UpgradePlanDialogType } from "../../MarketingDialogs/UpgradePlanDialog";
import CheckoutDialog from "../Billing/CheckoutDialog";
import { DowngradePlanDialog } from "../Billing/DowngradePlanDialog";
import { TextInput } from "../components";
import { CancelSubscribtionMessage } from "../components/NextPaymentSection";
import UploadAvatar from "../components/UploadAvatar";
import BillingDataService from "../dataservices/billingDataService";

const logger = getLogger(LogGroup.AUTH);

function checkIfUserIsOnlyAdminInTeams(teamModels, userId) {
    return teamModels.reduce((acc, team) => {
        if (team.has("customerId")) {
            const members = Object.values(team.get("members"));
            let adminCount = members.filter(member => member.role === "admin").length;
            if (adminCount === 1 && team.get("members")[userId].role === "admin") {
                return true;
            }
        }
        return acc;
    }, false);
}

async function checkIfUserHasPastDueSubscription() {
    const subscription = await Api.subscriptions.get({ customer_type: CustomerType.INDIVIDUAL });
    return (subscription && subscription[0] && subscription[0].status === SubscriptionStatus.PAST_DUE);
}

function hasPasswordProvider(user) {
    return !!user.providerData.find(p => p.providerId === "password");
}

function hasGoogleProvider(user) {
    return !!user.providerData.find(p => p.providerId === "google.com");
}

const StyledSubscriptionStatus = styled.div`
    text-transform: capitalize;
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.5px;

    span {
        margin-left: 6px;
    }
`;

const StyledCancelButton = styled(Button)`
    &&& {
        color: #666666;
    }
`;

const StyledUpgradeButton = styled(Button)`
    &&& {
        color: #ffffff;
        background-color: #FFBB43;
    }
`;

const StyledUIPane = styled(UIPane)`
    &&& {
        // on Safari, when the url bar is at the bottom, the bottom of the pane is cut off
        ${props => props.isMobile && css`padding-bottom: 70px;`}
    }
`;

const LinkButton = styled.span`
    color: #11a9e2;
    cursor: pointer;
`;

class AccountPane extends Component {
    constructor(props) {
        super(props);
        const firebaseUser = props.firebaseUser;
        this.state = {
            avatarUrl: firebaseUser.photoURL,
            currentPane: "account",
            uid: firebaseUser.uid,
            email: firebaseUser.email,
            emailVerified: firebaseUser.emailVerified,
            emailVerificationSent: false,
            emailVerificationStatusText: "Email is not verified - Click to send verification email",
            dialogMessage: null,
            name: firebaseUser.displayName || "",
            originalEmail: firebaseUser.email,
            previousEmail: firebaseUser.email,
            user: firebaseUser,
            userHasGoogleProvider: hasGoogleProvider(firebaseUser),
            userGoogleLinkFetching: false,
            userGDriveEnabled: app.user.get("isGDriveEnabled"),
            userGDriveFetching: false,
            canDeleteUser: Object.values(app.user.workspaces).length === 1,
            ssoStatus: null,
            fetching: true,
            subscription: null,
            paymentMethod: null,
        };
    }

    componentDidMount() {
        const { uid } = this.state;

        const workspaceId = AppController.workspaceId;
        const hasTeams = app.user.features.isFeatureEnabled(FeatureType.TEAMS, workspaceId);
        const canManageTeamBilling = app.user.features.isFeatureEnabled(FeatureType.MANAGE_TEAM_BILLING, workspaceId);

        Api.ssoStatus.get({ uid })
            .then(({ status }) => this.setState({ fetching: false, ssoStatus: status }))
            .catch(err => {
                logger.error(err, "Api.ssoStatus.get() failed");

                ShowErrorDialog({
                    error: "Uh oh, something isn't right",
                    message: "Looks like something unexpected has occurred. Please try again or contact us at support@beautiful.ai"
                });
            });

        // To make sure we're not getting error when a user doesn't actually
        // supposed to have access
        if (workspaceId !== "personal" && hasTeams && !canManageTeamBilling) {
            return;
        }

        this.loadBillingData();
    }

    handleUpgradeToV11 = async () => {
        if (await ShowConfirmationDialog({
            title: "Are you sure you want to upgrade to the new version of Beautiful.ai?",
            message: "Once you upgrade, you will not be able to return to the current version. Presentations and slides created or edited with this version of Beautiful.ai will not be editable during collaboration with users still using the older version."
        })) {
            AppController.user.update({ _migrated: true });
            AppController.showLibrary();
        }
    }

    openManageTeamPane = async () => {
        const defaultTeams = ds.teams.getDefaultTeams();
        const ownedTeam = defaultTeams.find(team => team.getUserRole() === "owner");

        const workspaceId = ownedTeam.get("orgId");
        if (workspaceId !== AppController.workspaceId) {
            await AppController.switchWorkspace(workspaceId);
        }

        browserHistory.push("/account/manage-default-team");
    }

    showPricingPage = (currentPlan = "basic") => {
        window.open(getPricingPageUrl(app && app.user.hasTakenTrial, app && app.user.has("hasTakenTeamTrial"), currentPlan), "_blank");
    };

    get hasSubscription() {
        const { subscription } = this.state;
        return subscription != null && subscription.product != "basic" && subscription.id != null;
    }

    get isInTrial() {
        const { subscription } = this.state;
        return subscription && subscription.status == "trialing";
    }

    updateUserGoogleLinkState() {
        const user = this.props.firebaseUser;
        const userHasGoogleProvider = hasGoogleProvider(this.props.firebaseUser);
        this.setState({ user, userHasGoogleProvider });
    }

    setUserGDriveFetching(userGDriveFetching) {
        this.setState({ userGDriveFetching });
    }

    setUserGoogleLinkFetching(userGoogleLinkFetching) {
        this.setState({ userGoogleLinkFetching });
    }

    handleChange(name) {
        return event => {
            this.setState({ [name]: event.target.value });
        };
    }

    handleChangePassword() {
        ShowDialog(ChangePasswordDialog);
    }

    handleDeleteAccount() {
        const { userGDriveEnabled } = this.state;

        ShowDialog(ValidatedDeleteDialog, {
            title: "Are you sure you want to delete your account?",
            message:
                "All your presentations and other data will be deleted from Beautiful.ai. You will not be able to undo this action.",
            prompt:
                "Please type your email address to confirm deletion of your account:",
            validateStr: app.user.getEmail(),
            callback: async isValidated => {
                if (isValidated) {
                    const userId = app.user.id;
                    const isPastDueFoundOnUser = await checkIfUserHasPastDueSubscription();
                    if (isPastDueFoundOnUser) {
                        ShowErrorDialog({
                            error: "Failed to delete user account",
                            message: "You cannot delete your account due to an outstanding payment on your subscription. " +
                                "Please email support@beautiful.ai for assistance."
                        });
                        return;
                    }
                    await ds.teams.loadModels();
                    if (checkIfUserIsOnlyAdminInTeams(ds.teams.models, userId)) {
                        ShowErrorDialog({
                            error: "Failed to delete user account",
                            message: "You cannot delete your account if you're the only admin of a pro team."
                        });
                        return;
                    }
                    if (userGDriveEnabled) {
                        await gDrive.disable();
                    }
                    ds.shutdown();
                    Api.deleteUser.delete().then(() => {
                        trackActivity("Auth", "DeletedAccount", null, null, { workspace_id: "all" }, { audit: true });
                        trackActivity("Auth", "Logout", null, null, { workspace_id: "all" }, { audit: true });
                        window.location = "/logout";
                    });
                }
            }
        });
    }

    async loadBillingData() {
        try {
            const [paymentMethod, subscription] = await Promise.all([
                BillingDataService.fetchPaymentMethod(AppController.orgId),
                BillingDataService.fetchSubscription(AppController.orgId)
            ]);

            this.setState({ paymentMethod, subscription });
        } catch (err) {
            logger.error(err, "loadBillingData() failed", { workspaceId: AppController.orgId ?? "personal" });
        }
    }

    async updateProfile(updates) {
        const { userMenu } = this.props;
        const { user } = this.state;
        await auth().updateProfile(user, updates)
            .then(() => {
                if (updates.photoURL !== undefined) {
                    //userMenu is passed from the editor which still uses a backbone menu so we need to call updateAvatar
                    if (userMenu) {
                        userMenu.updateAvatar();
                    } else {
                        //else the profile is updated from the presentation library which is using react
                        setGlobal({ accountAvatarUrl: updates.photoURL });
                    }
                }
            })
            .catch(err => {
                logger.error(err, "user.updateProfile() failed");
            });
    }

    async updateAvatar(obj) {
        this.setState({
            avatarUrl: obj.avatar
        });
        await this.updateProfile({ photoURL: obj.avatar });
    }

    checkForVerifiedEmail() {
        app.user.waitForVerifiedEmail().then(() => {
            this.setState({
                emailVerified: true
            });
        });
    }

    async updateEmail() {
        const { email, originalEmail, previousEmail, user } = this.state;

        // No spam requests with same email over and over
        if (previousEmail === email) {
            return false;
        }

        app.user
            .updateEmailAddress(email)
            .then(() => {
                this.setState({
                    emailVerificationStatusText: "Verification email sent.",
                    previousEmail: email
                });
                ShowMessageDialog({
                    title: "Verification email sent.",
                    message:
                        "Please check your email for a verification link and return to this page after you click the link."
                });
            })
            .catch(err => {
                switch (err.code) {
                    case "auth/requires-recent-login":
                        ShowDialog(ReauthPasswordDialog, {
                            callback: success => {
                                this.updateEmail();
                            },
                            onCancel: () => {
                                this.setState({
                                    email: originalEmail,
                                    previousEmail: null
                                });
                                ShowErrorDialog({
                                    error: "Unable to change email",
                                    message:
                                        "You must reauthenticate your account in order to change your email address."
                                });
                            }
                        });
                        break;
                    case "auth/invalid-email":
                    case "auth/email-already-in-use":
                    default:
                        this.setState({
                            email: originalEmail,
                            previousEmail: null
                        });
                        ShowErrorDialog({
                            error: "Error sending verification email.",
                            message: err.message
                        });
                        break;
                }
            });
    }

    renderVerifiedStatus(statusText) {
        const { emailVerified, emailVerificationSent, user } = this.state;
        if (user.emailVerified) {
            return null;
        } else {
            this.checkForVerifiedEmail();
            return (
                <div
                    className={classNames({
                        ["email-verification"]: true,
                        ["email-not-verified"]: true,
                        ["email-notice-button"]:
                            !emailVerificationSent && !emailVerified
                    })}
                    onClick={() => {
                        user.sendEmailVerification().then(() => {
                            this.setState({
                                emailVerificationStatusText:
                                    "Verification email sent",
                                emailVerificationSent: true
                            });
                        });
                    }}
                >
                    {statusText}
                </div>
            );
        }
    }

    getGDriveLink() {
        return (<a href="https://drive.google.com/drive" target="_blank">your Google Drive</a>);
    }

    handleLinkWithGoogle = async () => {
        this.setUserGoogleLinkFetching(true);
        // Do google sign in flow which will bind the firebase accoint to the google account
        // used for signing in
        googleAuth.authenticate("profile email", "/login");
    }

    handleUnlinkGoogle = async () => {
        const userGDriveEnabled = this.state.userGDriveEnabled;
        ShowConfirmationDialog({
            title: "Disconnect from Google",
            message: `In order to disconnect, you must have a fallback password for login, otherwise you will have to reset your password via email.${userGDriveEnabled ? " Your Google Drive account will also be disconnected." : ""}`,
            acceptCallback: async () => {
                this.setUserGoogleLinkFetching(true);

                try {
                    if (userGDriveEnabled) {
                        this.setUserGDriveFetching(true);
                        try {
                            await gDrive.disable();
                        } catch (err) {
                            // fail gracefully if token is invalid
                            logger.error(err, "gDrive.disable() failed");
                        }
                    }

                    const { auth, firebaseUser } = this.props;

                    logger.info("Unlinking Google account from Firebase");
                    await auth.unlinkProvider(firebaseUser, "google.com");

                    logger.info("Deleting credentials from server");
                    await Api.unlinkGoogleAuth.delete();

                    logger.info("Unlinking Google account successful");

                    if (!hasPasswordProvider(firebaseUser)) {
                        logger.info("No password provider, sending password reset email");
                        await auth.sendPasswordResetEmail(firebaseUser.email);
                    }

                    this.updateUserGoogleLinkState();
                } catch (err) {
                    logger.error(err, "unlink google failed");
                }

                if (userGDriveEnabled) {
                    this.setState({ userGDriveEnabled: false });
                    this.setUserGDriveFetching(false);
                }

                this.setUserGoogleLinkFetching(false);
            }
        });
    }

    currentUserGoogleEmail() {
        return (this.props.firebaseUser.providerData
            .find(p => p.providerId === "google.com") || {})
            .email;
    }

    renderPlanInfo() {
        const { subscription } = this.state;
        const workspaceId = AppController.workspaceId;

        // if a user is a Pro/Librarian user in a team, they can't manage the team billing
        const hasTeams = app.user.features.isFeatureEnabled(FeatureType.TEAMS, workspaceId);
        const canManageTeamBilling = app.user.features.isFeatureEnabled(FeatureType.MANAGE_TEAM_BILLING, workspaceId);

        if (!subscription || (hasTeams && !canManageTeamBilling)) {
            return null;
        }

        let productText = "";
        let intervalText = "";
        let upradeOrCancelPlan = "cancel";
        const { interval, product } = subscription;

        // If the user is in an org, we need to get the plan from the org
        if (interval && product) {
            productText = product;

            intervalText = `${interval}ly`;
            if (intervalText === "yearly") {
                intervalText = "annual";
            }
        } else {
            const userPlan = ds.userPlans.models[0];
            productText = userPlan.get("name") === "free" ? "Basic" : userPlan.get("name");
            intervalText = "free";
            upradeOrCancelPlan = "upgrade";
        }
        return (<Section title="Current Plan">
            {<StyledSubscriptionStatus>
                {productText}
                <span>({intervalText})</span>
            </StyledSubscriptionStatus>}
            <Gap10 />
            {this.renderUpgradeCancelPlanButton(upradeOrCancelPlan)}
            <Gap20 />
            <div>
                For more billing options, including payment management and history, please log in to our desktop app and navigate to Billing.
            </div>
        </Section>);
    }

    renderUpgradeCancelPlanButton(upgradeOrCancelPlan) {
        const { subscription } = this.state;
        if (upgradeOrCancelPlan === "cancel") {
            if (this.hasSubscription) {
                if (subscription && subscription.cancel_at_period_end) {
                    const currentPlan = AppController.orgId ? CustomerType.TEAM : CustomerType.INDIVIDUAL;
                    // in canceled subscription period
                    return (
                        <YellowButton fullWidth color="primary" onClick={this.handleReactiveSubscription}>
                            {currentPlan === "team" ? "Reactivate Team Plan" : "Reactivate Pro Plan"}
                        </YellowButton>
                    );
                }
            }

            if (this.isInTrial) {
                return (<BlueButton fullWidth onClick={this.handleCancelSubscription}>
                    Cancel free trial
                </BlueButton>);
            }

            return (<StyledCancelButton
                fullWidth
                variant="contained"
                onClick={this.handleCancelSubscription}
            >
                Cancel plan
            </StyledCancelButton>);
        } else {
            // organizationId is undefined, so this is a personal workspace
            return (<StyledUpgradeButton fullWidth onClick={this.handleClick} >
                Upgrade plan
            </StyledUpgradeButton>);
        }
    }

    handleClick = event => {
        const workspaceId = AppController.workspaceId;
        const isPro = !app.user.features.isFeatureEnabled(FeatureType.UPGRADE, workspaceId);
        const { status } = app.user.trialStatus || {};

        if (workspaceId === "personal") {
            if (status === "trial_ended" || status === "trialing") {
                ShowDialog(CheckoutDialog);
            } else if (!isPro) {
                const props = {
                    cta: "Menubar",
                };
                openPricingPage("basic", props);
            }
        } else {
            ShowUpgradeDialog({
                type: UpgradePlanDialogType.UPGRADE_PLAN,
                analytics: { cta: "Menubar" },
                workspaceId
            });
        }
    }

    handleReactiveSubscription = async () => {
        const organizationId = AppController.orgId;
        const customerType = AppController.orgId ? CustomerType.TEAM : CustomerType.INDIVIDUAL;

        await Api.subscriptions.put({
            orgId: organizationId,
            customerType,
            type: "reactive_subscription"
        });
        return this.loadBillingData();
    };

    handleCancelSubscription = async event => {
        const organizationId = AppController.orgId;
        const { subscription } = this.state;
        const isOrganization = organizationId !== null && organizationId !== undefined;
        const isInDunning = subscription.status === SubscriptionStatus.PAST_DUE;
        const nextBillDate = moment.unix(subscription.current_period_end).format("MMM DD, YYYY");

        ShowConfirmationDialog({
            title: `Are you sure you want to cancel your ${this.isInTrial ? "trial" : organizationId ? "Team plan" : "Pro plan"}?`,
            message: CancelSubscribtionMessage({ isInDunning, nextBillDate, isInTrial: this.isInTrial, isOrganization }),
            cancelButtonLabel: "Never mind",
            okButtonLabel: `Cancel ${this.isInTrial ? "trial" : "plan"}`
        })
            .then(async accept => {
                if (!accept) {
                    return;
                }

                await BillingDataService.cancelSubscription(subscription.id, organizationId, { immediate: isInDunning });
                this.loadBillingData();
                ShowDialog(DowngradePlanDialog, {
                    workspaceId: organizationId || "personal",
                    fullScreen: true
                });
            })
            .catch(err => {
                logger.error(err, "handleCancelSubscription() failed", { subscriptionId: subscription?.id, workspaceId: organizationId ?? "personal" });

                ShowErrorDialog({
                    error: "An error occurred while canceling your subscription",
                    message: (
                        <Fragment>
                            <p>
                                <strong>Error: </strong>
                                {err.message}
                            </p>
                            <p>We apologize for the inconvenience. Please contact us at support@beautiful.ai.</p>
                        </Fragment>
                    )
                });
            });
    };

    render() {
        const {
            onClose,
            firebaseUser
        } = this.props;
        const {
            avatarUrl,
            email,
            emailVerificationStatusText,
            name,
            user,
            userHasGoogleProvider,
            userGoogleLinkFetching,
            userGDriveEnabled,
            userGDriveFetching,
            canDeleteUser,
            ssoStatus,
            fetching
        } = this.state;

        let prohibitGoogleDriveAccountLinking = app.user.features.isFeatureEnabled(
            FeatureType.PROHIBIT_GOOGLE_DRIVE_ACCOUNT_LINKING,
            AppController.orgId,
        );

        const workspaceId = AppController.workspaceId;
        const canManageTeamBilling = app.user.features.isFeatureEnabled(FeatureType.MANAGE_TEAM_BILLING, workspaceId);

        prohibitGoogleDriveAccountLinking = prohibitGoogleDriveAccountLinking && !userGDriveEnabled;

        const defaultTeams = ds.teams.getDefaultTeams();
        const hasTeams = defaultTeams.length > 0;
        const isOwnerInATeam = defaultTeams.some(team => team.getUserRole() === "owner");

        return (
            <StyledUIPane isMobile={app.isConstrained}>
                <UIPaneHeader onClose={onClose}>My Account</UIPaneHeader>
                <UIPaneContents>
                    <Loadable isLoading={fetching}>
                        {/*{!hasTeams && <Section title="Upgrade Beautiful.ai">*/}
                        {/*    <FlexBox left vertical>*/}
                        {/*        A new version of Beautiful.ai is available. We are providing a 3-month grace period during which you can choose to upgrade at your convenience. After October 1, 2024, your account will automatically be upgraded to the new version.*/}
                        {/*        <Gap10 />*/}
                        {/*        <span>For more information on upgrading, please click <a href="https://www.beautiful.ai/upgrade" target="_blank">here</a>.</span>*/}
                        {/*        <Gap20 />*/}
                        {/*        <strong>Please note: Presentations and slides created or edited with this version of Beautiful.ai will not be editable during collaboration with users still using the older version.</strong>*/}
                        {/*        <Gap20 />*/}
                        {/*        <BlueButton onClick={this.handleUpgradeToV11}>Upgrade Beautiful.ai...</BlueButton>*/}
                        {/*    </FlexBox>*/}
                        {/*</Section>}*/}
                        {/*{hasTeams && !isOwnerInATeam && < Section title="Upgrade Beautiful.ai available">*/}
                        {/*    <FlexBox left vertical>*/}
                        {/*        A new version of Beautiful.ai is available. We are providing a 3-month grace period during which users can choose to upgrade at your convenience. After October 1, 2024, your account will automatically be upgraded to the new version.*/}
                        {/*        <Gap10 />*/}
                        {/*        <span>For more information on upgrading, please click <a href="https://www.beautiful.ai/upgrade" target="_blank">here</a>.</span>*/}
                        {/*        <Gap20 />*/}
                        {/*        <strong>Contact the owner of your team to get your team upgraded to the new version.</strong>*/}
                        {/*    </FlexBox>*/}
                        {/*</Section>}*/}
                        {/*{hasTeams && isOwnerInATeam && <Section title="Upgrade Beautiful.ai available">*/}
                        {/*    <FlexBox left vertical>*/}
                        {/*        A new version of Beautiful.ai is available. We are providing a 3-month grace period during which users can choose to upgrade at your convenience. After October 1, 2024, your account will automatically be upgraded to the new version.*/}
                        {/*        <Gap10 />*/}
                        {/*        <span>For more information on upgrading, please click <a href="https://www.beautiful.ai/upgrade" target="_blank">here</a>.</span>*/}
                        {/*        <Gap20 />*/}
                        {/*        <strong>Open the <LinkButton onClick={this.openManageTeamPane}>Manage Team pane</LinkButton> to upgrade your team.</strong>*/}
                        {/*    </FlexBox>*/}
                        {/*</Section>}*/}

                        <Section title="Profile Settings">
                            <FlexBox horizontalAlign="left" verticalAlign="top" style={{ display: app.isConstrained ? "block" : "flex" }}>
                                <div className="avatar-editor">
                                    <UploadAvatar
                                        src={avatarUrl || ""}
                                        label="Avatar"
                                        update={obj => this.updateAvatar(obj)}
                                        attribute="avatar"
                                        showSilhouette
                                    />
                                </div>
                                <div className="input-column account-info">
                                    <TextInput
                                        label="Name"
                                        id="user-name"
                                        curValue={name}
                                        handleChange={this.handleChange("name")}
                                        handleBlur={async () => {
                                            const displayName = removeUrlsFromText(name);
                                            if (displayName !== "") {
                                                await this.updateProfile({
                                                    displayName
                                                });

                                                // Reflect the change in the state
                                                this.setState({ name: displayName });
                                            } else {
                                                ShowErrorDialog({
                                                    error:
                                                        "Unable to change display name",
                                                    message: `The display name can't be blank.`
                                                });
                                            }
                                        }}
                                    />
                                    <Gap20 />
                                    <TextInput
                                        label="Email"
                                        classNames={["email-field"]}
                                        id="email"
                                        disabled={ssoStatus === "enabled" || ssoStatus === "strict"}
                                        curValue={email}
                                        handleChange={this.handleChange("email")}
                                        handleBlur={async () => {
                                            await this.updateEmail();
                                        }}
                                    >
                                        {this.renderVerifiedStatus(
                                            emailVerificationStatusText
                                        )}
                                    </TextInput>
                                    <Gap20 />
                                    {hasPasswordProvider(user) && ssoStatus !== "strict" && (
                                        <BlueButton
                                            onClick={this.handleChangePassword}
                                        >
                                            Change Password...
                                        </BlueButton>
                                    )}
                                </div>
                            </FlexBox>
                        </Section>

                        {!app.isConstrained && !isPPTAddin &&
                            <Fragment>
                                <Section title="Account Linking">
                                    {userHasGoogleProvider && (
                                        <div style={{ marginBottom: ".5em", fontSize: "1.1em" }}>
                                            {/* when the user account's email and Google email differ, show it */}
                                            {firebaseUser.email != this.currentUserGoogleEmail() && (
                                                <div className="gdrive-notice-text" style={{ marginBottom: ".75em" }}>
                                                    Linked with <b>{this.currentUserGoogleEmail()}</b>
                                                </div>
                                            )}

                                            <GoogleButton
                                                fullWidth={false}
                                                label="Disconnect Account From Google"
                                                disabled={userGoogleLinkFetching}
                                                onClick={this.handleUnlinkGoogle}>
                                                {userGoogleLinkFetching && <Spinner />}
                                            </GoogleButton>
                                        </div>
                                    )}
                                    {!userHasGoogleProvider && (
                                        <GoogleButton
                                            label="Link Account With Google"
                                            fullWidth={false}
                                            disabled={userGoogleLinkFetching}
                                            onClick={this.handleLinkWithGoogle}>
                                            {userGoogleLinkFetching && <Spinner />}
                                        </GoogleButton>
                                    )}
                                </Section>

                                <Section title="Google Drive">
                                    {prohibitGoogleDriveAccountLinking &&
                                        <div className="gdrive-notice-text">
                                            Google Drive integration has been disabled by a Team Owner.
                                        </div>
                                    }
                                    {!prohibitGoogleDriveAccountLinking &&
                                        <>
                                            {userHasGoogleProvider && userGDriveEnabled &&
                                                <div className="gdrive-notice-text">
                                                    Your presentations are synced with your Google Drive. You can find your presentations in {this.getGDriveLink()}.
                                                </div>
                                            }
                                            {userHasGoogleProvider && !userGDriveEnabled &&
                                                <div className="gdrive-notice-text">
                                                    Connect your Google Drive to have all your presentations available in your Google Drive.
                                                </div>
                                            }
                                            {!userHasGoogleProvider &&
                                                <div className="gdrive-notice-text">
                                                    Link your account with Google to sync your presentations with Google Drive.
                                                </div>
                                            }
                                        </>
                                    }
                                    {userHasGoogleProvider && userGDriveEnabled &&
                                        <GoogleButton
                                            label="Disconnect Google Drive"
                                            fullWidth={false}
                                            disabled={userGDriveFetching || prohibitGoogleDriveAccountLinking}
                                            onClick={() => {
                                                this.setUserGDriveFetching(true);
                                                gDrive.disable()
                                                    .then(() => {
                                                        this.setState({ userGDriveEnabled: false }, () => {
                                                            ShowMessageDialog({
                                                                title: "Disconnect Google Drive",
                                                                message: <span>Your account was successfully disconnected from your Google Drive.</span>
                                                            });
                                                            trackActivity("GDrive", "DisconnectSuccess", null, null, {}, { audit: true });
                                                        });
                                                    })
                                                    .catch(err => logger.error(err, "gDrive.disable() failed"))
                                                    .finally(() => {
                                                        this.setUserGDriveFetching(false);
                                                    });
                                            }}>
                                            {userGDriveFetching && <Spinner />}
                                        </GoogleButton>
                                    }
                                    {userHasGoogleProvider && !userGDriveEnabled &&
                                        <GoogleButton
                                            label="Connect Google Drive"
                                            fullWidth={false}
                                            disabled={userGDriveFetching || prohibitGoogleDriveAccountLinking}
                                            onClick={() => {
                                                trackActivity("GDrive", "ConnectCTAClick", null, null, {}, { audit: true });
                                                this.setUserGDriveFetching(true);

                                                gDrive.getState()
                                                    .then(({ hasOfflineAccess }) => {
                                                        if (!hasOfflineAccess) {
                                                            return gDrive.grantOfflineAccessWithDialogIfNeeded(firebaseUser.uid);
                                                        }
                                                    })
                                                    .then(() => gDrive.enable())
                                                    .then(() => {
                                                        trackActivity("GDrive", "ConnectSuccess", null, null, {}, { audit: true });
                                                        this.setState({ userGDriveEnabled: true }, () => {
                                                            ShowMessageDialog({
                                                                title: "Connect Google Drive",
                                                                message: <span>Your account was successfully connected with your Google Drive. You can find your presentations in {this.getGDriveLink()}.</span>
                                                            });
                                                        });
                                                    })
                                                    .catch(err => {
                                                        if (err instanceof googleAuth.GoogleAuthFlowWasInterruptedError) {
                                                            // User cancelled, ignore
                                                            return;
                                                        }

                                                        if (err.message === "Wrong user") {
                                                            ShowMessageDialog({
                                                                title: "Error",
                                                                message: <span>You must have selected a wrong account, your Google Drive account must match with the Google account you linked with Beautiful.ai</span>
                                                            });
                                                            return;
                                                        }

                                                        logger.error(err, "failed to enable google drive integration");
                                                    })
                                                    .finally(() => {
                                                        this.setUserGDriveFetching(false);
                                                    });
                                            }}>
                                            {userGDriveFetching && <Spinner />}
                                        </GoogleButton>
                                    }
                                </Section>

                                {canDeleteUser && <Section title="Account Deletion">
                                    <ScaryButton onClick={() => this.handleDeleteAccount()}>
                                        Delete My Account...
                                    </ScaryButton>
                                </Section>}
                                {!canDeleteUser && <Section title="Account Deletion">
                                    <div className="delete-notice-text">
                                        Note: you cannot delete your account because you are a member of a team.
                                        If you wish to delete your account, first cancel your team subscription or ask your Team Owner to remove you from the team.
                                    </div>
                                    <ScaryButton disabled>
                                        Delete My Account...
                                    </ScaryButton>
                                </Section>}
                            </Fragment>}
                    </Loadable>
                    {
                        app.isConstrained &&
                        <>
                            {this.renderPlanInfo()}
                            <div style={{ marginTop: "20px" }}>
                                <FlexBox center>
                                    <LogOutButton />
                                </FlexBox>
                                <Gap20 />
                            </div>
                        </>
                    }
                </UIPaneContents>
            </StyledUIPane>
        );
    }
}

export default withFirebaseAuth(withFirebaseUser(AccountPane));
